import { getCountriesForTimezone } from 'countries-and-timezones';
import { countries, countryIds } from '../../shared/constants/countries';
import { categoriesNames } from '../../shared/constants/products';
import { getCurrentTimezone } from '../../shared/helpers/getCurrentTimeZone';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { SessionStorageService } from '../../shared/services/sessionStorage.service';
import { TokenService } from '../../shared/services/token.service';
import { setDashboardMode } from '../../store/dashboard';
import { DashboardMode } from '../../store/dashboard/dashboard.reducer';
import { setImpersonated } from '../../store/user';
import { setAdminCountryCode } from '../../store/user/user.actions';
import { adminCountriesApi } from '../admin-countries';
import { setSuccessMsgHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse, tagTypes } from '../index';
import {
  ContactMsgRequest,
  CreateAddressRequest,
  CreateSubCategoryResponse,
  GetSubCategoriesResponse,
  NotificationSettingResponse,
  PurchaseRequest,
  PurchaseResponse,
  RestaurantUser,
  SupplierUser,
  UpdateAddressRequest,
  UpdateAdminProfile,
  UpdateColumnsResponse,
  UpdateDeliveryWindows,
  UpdateHiddenColumnsReq,
  UpdateNotificationSettingRequest,
  UpdatePasswordRequest,
  VenueResponse,
  VerifyPromoCodeRes,
  VerifySupplierResponse,
} from './types';

export const userApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<RestaurantUser | SupplierUser, boolean | void>({
      query: () => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId || ''}`,
          method: HTTP.GET,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          if (!LocalStorageService.getItem('a_token')) {
            dispatch(setImpersonated(false));
          }
          const { data: user } = await queryFulfilled;
          if ((user?.country_id || (Array.isArray(user?.company_addresses) && user?.company_addresses[0]?.country)) && !user?.is_admin) {
            // @ts-ignore
            const country = user?.company_addresses[0]?.country;
            const countryCode =
              countryIds.find(({ id }) => id === user?.country_id)?.code?.toUpperCase() ||
              countries.find((el) => el.label.toLowerCase() === country?.toLowerCase())?.value ||
              countries.find((el) => getCountriesForTimezone(getCurrentTimezone()).find((c) => c.id === el.value))?.value ||
              null;
            LocalStorageService.clear('country_code_admin');
            LocalStorageService.setItem('country', countryCode);
            if (countryCode) {
              dispatch(adminCountriesApi.endpoints.getCountriesDataForUser.initiate(countryCode, { forceRefetch: true }));
            }
          } else if (user?.is_admin && user?.country_data_for) {
            dispatch(setAdminCountryCode(user.country_data_for === 'all_countries' ? null : user.country_data_for));
            LocalStorageService.setItem(
              'country',
              user.country_data_for === 'all_countries' || user.country_data_for === 'eu' || user.country_data_for === 'rest_of_world'
                ? null
                : user.country_data_for.toUpperCase(),
            );
            if (user.country_data_for !== 'all_countries' && user.country_data_for !== 'rest_of_world') {
              dispatch(
                adminCountriesApi.endpoints.getCountriesDataForUser.initiate(user.country_data_for.toLowerCase(), { forceRefetch: true }),
              );
            } else {
              SessionStorageService.clear('country_currency');
            }
          } else if (user?.is_admin && !user?.country_data_for) {
            SessionStorageService.clear('country_currency');
          }
          dispatch(setDashboardMode(user.demo_exited ? DashboardMode.EMPTY : DashboardMode.DEMO));
        } catch (e) {
          console.log(e);
        }
      },
      providesTags: ['Me'],
    }),

    onboarding: builder.mutation<SuccessResponse, FormData>({
      query: (body) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/onboarding`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Me', 'Company', 'Supplier', 'Connection'],
    }),

    verifySupplier: builder.mutation<VerifySupplierResponse, { supplier_id: number; toast?: boolean }>({
      query: ({ supplier_id }) => {
        return {
          url: `/verify_supplier`,
          method: HTTP.POST,
          body: { supplier_id },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (args.toast) {
            await setSuccessToast(dispatch, queryFulfilled, `${data?.supplier?.company.name || 'Supplier'} has been verified`);
          } else {
            LocalStorageService.clear('verify_supplier');
            await setSuccessMsgHelper(dispatch, queryFulfilled, `${data?.supplier?.company.name || 'Supplier'} has been verified`);
          }
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Connection', 'Suppliers'],
    }),

    updatePassword: builder.mutation<SuccessResponse, UpdatePasswordRequest>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/update_password`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Password Updated');
      },
    }),

    updateAdminProfile: builder.mutation<SuccessResponse, UpdateAdminProfile>({
      query({ showToast, ...body }) {
        return {
          url: `/admin/update_admin`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted({ showToast }, { dispatch, queryFulfilled }) {
        if (showToast) {
          await setSuccessToast(dispatch, queryFulfilled, 'Profile Updated');
        }
      },
      invalidatesTags: ['Me'],
    }),

    updateAdminCountry: builder.mutation<SuccessResponse, number>({
      query(country_data_for) {
        return {
          url: `/admin/update_admin`,
          method: HTTP.PATCH,
          body: { user: { country_data_for } },
        };
      },
      invalidatesTags: tagTypes,
    }),

    hideTooltips: builder.mutation<SuccessResponse, void>({
      query() {
        return {
          url: `/users/tooltips`,
          method: HTTP.PATCH,
        };
      },
      invalidatesTags: ['Me'],
    }),

    sendContactMsg: builder.mutation<SuccessResponse, ContactMsgRequest>({
      query(body) {
        return {
          url: `/users/send_contact_message`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessMsgHelper(dispatch, queryFulfilled, 'We will be in touch soon');
      },
    }),

    getAddress: builder.query<VenueResponse, void>({
      query: () => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/venue_information`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Address'],
    }),

    updateDeliveryWindows: builder.mutation<SuccessResponse, UpdateDeliveryWindows>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/update_delivery_windows`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessMsgHelper(dispatch, queryFulfilled, 'Updated');
      },
      invalidatesTags: ['Me'],
    }),

    setPwaInstalled: builder.mutation<SuccessResponse, void>({
      query() {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/install_app`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'App installed successfully');
      },
    }),

    createAddress: builder.mutation<SuccessResponse, CreateAddressRequest>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/address`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessMsgHelper(dispatch, queryFulfilled, 'Address added');
      },
      invalidatesTags: ['Address'],
    }),

    createSubCategory: builder.mutation<CreateSubCategoryResponse, string>({
      query(name) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/create_sub_categories`,
          method: HTTP.POST,
          body: {
            sub_category: {
              name,
              supplier_id: userId,
            },
          },
        };
      },
      invalidatesTags: ['Sub_Category'],
    }),

    getSubCategories: builder.query<GetSubCategoriesResponse, string | void>({
      query(keyword) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/list_sub_categories`,
          method: HTTP.GET,
          params: {
            'q[keyword]': keyword || '',
          },
        };
      },
      providesTags: ['Sub_Category'],
    }),

    updateAddress: builder.mutation<SuccessResponse, UpdateAddressRequest>({
      query({ addressId, ...body }) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/address/${addressId}`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessMsgHelper(dispatch, queryFulfilled, 'Address updated');
      },
      invalidatesTags: ['Address'],
    }),

    deleteAddress: builder.mutation<SuccessResponse, number>({
      query(args) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/address/${args}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessMsgHelper(dispatch, queryFulfilled, 'Address deleted');
      },
      invalidatesTags: ['Address'],
    }),

    getNotificationSetting: builder.query<NotificationSettingResponse, void>({
      query() {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/notification_setting`,
          method: HTTP.GET,
        };
      },
    }),

    setProfitMarginMode: builder.mutation<SuccessResponse, void>({
      query() {
        return {
          url: `/show_profit_margin`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (draft) => {
            draft.profit_margin = !draft.profit_margin;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Me'],
    }),

    sendReferral: builder.mutation<SuccessResponse, { name: string; email: string }[]>({
      query(args) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/share_the_love`,
          method: HTTP.POST,
          body: { referrel_data: args },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Successfully sent');
      },
    }),

    unsubscribe: builder.mutation<SuccessResponse, { user_id: number; email_name: string }>({
      query(body) {
        return {
          url: `/unsubscribe_email`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Successfully unsubscribed');
      },
    }),

    updateNotificationSetting: builder.mutation<NotificationSettingResponse, UpdateNotificationSettingRequest>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/update_notification_setting`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getNotificationSetting', undefined, (draft) => {
            Object.assign(draft.notification_setting, args.notification_setting);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    updateHiddenColumns: builder.mutation<UpdateColumnsResponse, UpdateHiddenColumnsReq>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/update_hidden_coloumns`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (draft) => {
            if (draft.hidden_column) {
              draft.hidden_column.order_column = args.hidden_column.order_column;
              draft.hidden_column.product_column = args.hidden_column.product_column;
            } else {
              return draft;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    connectStripe: builder.mutation<SuccessResponse, string>({
      query: (code) => {
        return {
          url: `/stripe/connect`,
          method: HTTP.PATCH,
          body: { code },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Your Stripe account connected successfully');
      },
      invalidatesTags: ['Me'],
    }),

    getStripeLoginLink: builder.mutation<{ login_link: string; success: boolean }, void>({
      query: () => {
        return {
          url: `/stripe_login_link`,
          method: HTTP.POST,
        };
      },
    }),

    updateStripeCard: builder.mutation<SuccessResponse, { stripe_token: any }>({
      query: (body) => {
        return {
          url: `/stripe/update_card`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Payment method has been added');
      },
      invalidatesTags: ['Pay_method', 'Me'],
    }),

    attachPaymentMethod: builder.mutation<SuccessResponse, { stripe_id: string; method_id: string }>({
      query: (body) => {
        return {
          url: `stripe/attach_method`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Payment method has been added');
      },
      invalidatesTags: ['Pay_method'],
    }),

    removePaymentMethod: builder.mutation<SuccessResponse, { stripe_id: string; method_id: string }>({
      query: (body) => {
        return {
          url: `stripe/delete_method`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Payment method has been deleted');
      },
      invalidatesTags: ['Pay_method'],
    }),

    setDefaultPaymentMethod: builder.mutation<SuccessResponse, { stripe_id: string; method_id: string }>({
      query: (body) => {
        return {
          url: `stripe/set_default`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Payment method has been set as default');
      },
      invalidatesTags: ['Pay_method'],
    }),

    getPaymentMethods: builder.query<{ payment_methods: any[]; default_method: string | null }, string>({
      query: (stripe_id) => {
        return {
          url: `/stripe/payment_methods`,
          method: HTTP.GET,
          params: { stripe_id },
        };
      },
      providesTags: ['Pay_method'],
    }),

    purchaseMenuCosting: builder.mutation<PurchaseResponse, PurchaseRequest>({
      query: (body) => {
        return {
          url: `/payments/menu_costing`,
          method: HTTP.POST,
          body,
        };
      },
    }),

    purchaseProSuite: builder.mutation<PurchaseResponse, PurchaseRequest>({
      query: (body) => {
        return {
          url: `/payments/restaurent_pro_suite`,
          method: HTTP.POST,
          body,
        };
      },
    }),

    verifyPromoCode: builder.mutation<
      VerifyPromoCodeRes,
      {
        coupon_code: string;
        email: string;
        subscription_type: 'menu_costing' | 'restaurent_pro_suite';
        country_code: string;
      }
    >({
      query: (body) => {
        return {
          url: `/verify_coupon`,
          method: HTTP.POST,
          body,
        };
      },
    }),

    getAutoDiscount: builder.query<
      VerifyPromoCodeRes,
      { subscription_type: 'menu_costing' | 'restaurent_pro_suite'; email?: string; country_code: string }
    >({
      query: (params) => {
        return {
          url: `/auto_discount`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    onboardTeammate: builder.mutation<SuccessResponse, void>({
      query() {
        return {
          url: `/onboard_teammate`,
          method: HTTP.POST,
        };
      },
      invalidatesTags: ['Me'],
    }),

    onboardRestaurant: builder.mutation<SuccessResponse, void>({
      query() {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/onboard_restaurant`,
          method: HTTP.POST,
        };
      },
      invalidatesTags: ['Me', 'Company'],
    }),

    getCategories: builder.query<{ name: string; id: number }[], undefined>({
      query: () => {
        return {
          url: `/category_list`,
          method: HTTP.GET,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          if (Array.isArray(res?.data)) {
            const matchedData = categoriesNames.map((el) => {
              return {
                ...el,
                id: res.data.find((c) => c.name === el.value)?.id as number,
              };
            });
            SessionStorageService.setItem('categories', matchedData);
          }
        } catch (e) {
          console.log(e);
        }
      },
    }),
  }),

  overrideExisting: true,
});

export const {
  useMeQuery,
  useGetAddressQuery,
  useUpdateDeliveryWindowsMutation,
  useOnboardingMutation,
  useUpdatePasswordMutation,
  useSendContactMsgMutation,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useCreateSubCategoryMutation,
  useGetSubCategoriesQuery,
  useGetNotificationSettingQuery,
  useUpdateNotificationSettingMutation,
  usePrefetch,
  useVerifySupplierMutation,
  useSetProfitMarginModeMutation,
  useSendReferralMutation,
  useUpdateAdminProfileMutation,
  useUpdateHiddenColumnsMutation,
  useHideTooltipsMutation,
  useSetPwaInstalledMutation,
  useUnsubscribeMutation,
  useConnectStripeMutation,
  useUpdateStripeCardMutation,
  useGetPaymentMethodsQuery,
  useAttachPaymentMethodMutation,
  useRemovePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
  useUpdateAdminCountryMutation,
  usePurchaseMenuCostingMutation,
  useVerifyPromoCodeMutation,
  useGetAutoDiscountQuery,
  usePurchaseProSuiteMutation,
  useOnboardTeammateMutation,
  useGetStripeLoginLinkMutation,
  useGetCategoriesQuery,
  useOnboardRestaurantMutation,
} = userApi;
