import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export interface StyleProps {
  height?: number;
  inputFontSize?: number;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    box: {
      width: '100%',
      position: 'relative',
      paddingTop: 10,
    },
    paddingBox: {
      paddingTop: 30,
    },
    rootField: {
      padding: 0,
      width: '100%',
      margin: 0,
      '& .MuiFormLabel-root': {
        top: -26,
        marginBottom: 10,
        fontSize: 12,
        lineHeight: '16px',
        color: colorVariables.navy,
        transform: 'scale(1)',
      },
      '& .MuiInputBase-formControl.MuiInput-formControl': {
        margin: 0,
        padding: '5px 10px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colorVariables.steelGrey,
        borderRadius: 5,
        '& input::placeholder': {
          opacity: 1,
          color: colorVariables.steelGrey,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl:hover': {
        [theme.breakpoints.up(Sizes.desktop)]: {
          borderColor: colorVariables.blue,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-focused': {
        borderColor: colorVariables.blue,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-disabled': {
        backgroundColor: 'rgba(255,255,255, 0.2)',
        color: `${colorVariables.grey80} !important`,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-disabled:hover': {
        borderColor: colorVariables.steelGrey,
      },
    },
    root: {
      fontSize: ({ inputFontSize }) => inputFontSize || 16,
      fontWeight: 400,
      color: colorVariables.navy,
      height: ({ height }) => height || 48,
      backgroundColor: colorVariables.white,
      '&::before, &::after': {
        display: 'none',
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&.MuiInput-multiline': {
        height: 'auto',
        lineHeight: 1.5,
        marginTop: 30,
        fontSize: ({ inputFontSize }) => inputFontSize || 16,
      },
    },
    rootMultiline: {
      height: 'auto',
    },
    toolTip: {
      display: 'block',
      minWidth: 125,
      maxWidth: '100%',
      minHeight: 24,
      borderRadius: 4,
      textAlign: 'center',
      fontSize: 11,
      lineHeight: '24px',
      padding: '0 5px',
      color: `${colorVariables.white} !important`,
      backgroundColor: colorVariables.navy,
      position: 'absolute',
      bottom: ({ height }) => (height || 50) + 10,
      left: 0,
      [theme.breakpoints.down(Sizes.mobile)]: {
        bottom: ({ height }) => (height || 48) + 10,
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -14,
        left: 24,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `${colorVariables.navy} transparent transparent transparent`,
      },
    },
    toolTipMultiline: {
      bottom: 'auto !important',
      top: -40,
    },
    hideWarning: {
      display: 'none',
    },
    hidden: {
      height: 0,
      width: 0,
      position: 'absolute',
      zIndex: -999,
      opacity: 0,
      border: 'none !important',
      outline: 'none !important',
      '*:focus': {
        border: 'none !important',
        outline: 'none !important',
      },
    },
  }),
);
