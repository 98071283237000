import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';

import { useGoogleLoginMutation, useLoginMutation } from '../../../api/auth';
import { LoginRequest } from '../../../api/auth/types';
import { useMeQuery, userApi } from '../../../api/user';
import Logo from '../../../assets/images/logo/Logo_White.svg';
import { ThemedButton } from '../../../shared/components/themed-button';
import { FormikInput } from '../../../shared/components/formik-input';
import { GoogleIdentityService } from '../../../shared/services/google-identity.service';
import { TokenService } from '../../../shared/services/token.service';
import { useAppDispatch } from '../../../store';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';

import { useStyles } from './style';
import { useIsWhiteLabeled } from '../../../shared/hooks/useIsWhiteLabeled';

export const Login: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { replace } = useHistory();
  const auth_token = search.split('auth_token=')[1];
  const { logoUrl, name } = useCurrentTheme();
  const isWhiteLabeled = useIsWhiteLabeled();

  const [isErrorShown, setIsErrorShown] = useState(false);
  const [recaptchaShown, setRecaptchaShown] = useState(false);

  const isLoginAvailable = GoogleIdentityService.checkIfGoogleAvailable();

  const [login, { isSuccess, isLoading, error }] = useLoginMutation();
  const { isLoading: userLoading } = useMeQuery(undefined, {
    skip: !TokenService.getToken(),
  });
  const [googleLogin, { isLoading: googleLoading, isSuccess: googleSuccess }] = useGoogleLoginMutation();

  const isSubmitDisabled = isLoading || isSuccess || userLoading || googleLoading || googleSuccess;

  const onSubmit = (formValues: LoginRequest, formikHelpers: FormikHelpers<LoginRequest>) => {
    formikHelpers.setSubmitting(false);
    !recaptchaShown && login(formValues);
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().required('Required field').email('Please enter a valid email address'),
    password: Yup.string().required('Required field').min(8, 'Password too short'),
  });

  const onGLogin = (id_token: string) => {
    googleLogin({ id_token });
  };

  const hideErrorMsg = () => {
    setIsErrorShown(false);
  };

  const onRecaptchaChange = async (token: string | null) => {
    setRecaptchaShown(!token);
  };

  useEffect(() => {
    setIsErrorShown(!!error);
    // @ts-ignore
    if (error?.data?.attempts && error.data.attempts > 3) {
      setRecaptchaShown(true);
    }
  }, [error]);

  useEffect(() => {
    GoogleIdentityService.getIdToken(onGLogin);
    document.getElementById('ai-script')?.remove();
    document.querySelector('.chat-bubble')?.remove();
    document.getElementById('chatBubble')?.remove();
    return () => {
      GoogleIdentityService.onLogout();
      document.getElementById('gid-script')?.remove();
    };
  }, []);

  useEffect(() => {
    if (auth_token) {
      TokenService.setToken(auth_token);
      dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true })).then(() => {
        TokenService.setToken(auth_token);
        replace('/');
      });
    }
  }, [auth_token]);

  useLayoutEffect(() => {
    // Update the title
    document.title = `Login | Wholesale Online Ordering System for Restaurants | ${name === undefined ? ' ' : name || 'Open Pantry'}`;

    // Update the meta description
    const metaDescriptionTag = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    if (metaDescriptionTag) {
      metaDescriptionTag.content = `Log in to Australia's best wholesale online ordering system for restaurants and suppliers with menu costing software included.`;
    } else {
      // If the meta tag doesn't exist, create and append it to the head
      const newMetaTag = document.createElement('meta');
      newMetaTag.name = 'description';
      newMetaTag.content = `Log in to Australia's best wholesale online ordering system for restaurants and suppliers with menu costing software included.`;
      document.head.appendChild(newMetaTag);
    }

    // Cleanup function (optional)
    return () => {
      document.title = name === undefined ? ' ' : name || 'Open Pantry';
      // You can also remove the meta description tag if necessary
      const existingMetaTag = document.querySelector('meta[name="description"]');
      if (existingMetaTag) {
        document.head.removeChild(existingMetaTag);
      }
    };
  }, [name]);

  return (
    <div className={classes.root}>
      <div className={classes.logoBox}>
        <img src={logoUrl || Logo} alt='OpenPantry' className={classes.logo} style={{ objectFit: 'contain', maxHeight: 70 }} />
      </div>
      {isLoginAvailable ? (
        <>
          <div id='googleSignInBtn' className={classes.gBtnBox}></div>
          <div className={classes.orText}>OR</div>
        </>
      ) : (
        <>
          <br />
          <br />
        </>
      )}
      <div className={classes.loginFormBox}>
        <Formik initialValues={{ email: '', password: '' }} validationSchema={loginSchema} onSubmit={onSubmit}>
          {({ submitForm }) => (
            <Form>
              {isErrorShown && error && (
                <div className={classes.errorBox}>
                  <IconButton className={classes.closeErrorBtn} onClick={hideErrorMsg}>
                    <Close className={classes.closeError} />
                  </IconButton>
                  <div className={classes.errorText}>
                    {'data' in error ? error.data.message : 'The email or password you have entered is incorrect'}
                  </div>
                </div>
              )}
              <div className={classes.fieldBox}>
                <Field name='email'>
                  {(fieldProps: FieldProps) => (
                    <FormikInput {...fieldProps} type='email' label='Work Email' placeholder='Email' autoComplete='email' />
                  )}
                </Field>
              </div>
              <div className={classes.fieldBox}>
                <Field name='password'>
                  {(fieldProps: FieldProps) => (
                    <FormikInput {...fieldProps} type='password' label='Password' placeholder='Password' autoComplete='current-password' />
                  )}
                </Field>
              </div>
              <div className={classes.btnBox}>
                <ThemedButton title='Sign in to your account' onClick={submitForm} disabled={isSubmitDisabled || recaptchaShown} />
              </div>
              {recaptchaShown && (
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY as string}
                  onChange={onRecaptchaChange}
                  className={classes.recaptchaBox}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.bottomBox}>
        <div>
          <span>Forgot your password? </span>
          <Link to='/reset_password' className={classes.link}>
            Reset it here.
          </Link>
        </div>
        <div>
          <span>Don’t have an account? </span>
          <Link to='/signup' className={classes.link}>
            Get yours now.
          </Link>
        </div>
      </div>
      {isWhiteLabeled && (
        <div className={classes.poweredBox}>
          <span>Powered by</span>
          <img src={Logo} alt='OpenPantry' width={136} />
        </div>
      )}
    </div>
  );
};
