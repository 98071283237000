import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { rootApi } from '../../api';
import { CostPlan } from '../../api/admin-countries/types';
import { userApi } from '../../api/user';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { SessionStorageService } from '../../shared/services/sessionStorage.service';
import { TokenService } from '../../shared/services/token.service';
import { RootState } from '../index';

export const logoutThunk = createAsyncThunk('LOGOUT', async (_, { dispatch }) => {
  const settingsToSave = ['products_filter', 'products_sort', 'orders_sort', 'orders_filter', 'delivery_sort'].map((key) => ({
    key,
    value: LocalStorageService.getItem(key),
  }));
  LocalStorageService.clearAll();
  settingsToSave.forEach(({ key, value }) => {
    LocalStorageService.setItem(key, value);
  });
  dispatch(rootApi.util.resetApiState());
});

export const backToAdminThunk = createAsyncThunk('BACK_TO_ADMIN', async (_, { dispatch }) => {
  const adminToken = LocalStorageService.getItem('a_token');
  dispatch(setImpersonated(false));
  await dispatch(logoutThunk());
  TokenService.setToken(adminToken);
  window.history.replaceState(null, 'state', '/');
  dispatch(setImpersonated(false));
  dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true }));
});

export const backToSupplierThunk = createAsyncThunk('BACK_TO_SUPPLIER_THUNK', async (_, { dispatch, getState }) => {
  SessionStorageService.setItem('redirect_to_customers', (getState() as RootState).customers);
  LocalStorageService.clear('impersonated_customer');
  window.history.replaceState(null, 'state', '/');
  window.location.reload();
  dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true }));
});

export const setSuccessMsg = createAction<{ message: string | null }>('SET_SUCCESS_MSG');

export const setLearningMode = createAction<boolean>('SET_LEARNING_MODE');

export const setAdminCountryCode = createAction<string | null>('SET_ADMIN_COUNTRY_CODE');

export const openMainMenu = createAction('OPEN_MAIN_MENU');

export const openUseDesktopForTools = createAction<boolean>('OPEN_USE_DESKTOP_FOR_TOOLS');

export const setCartDeliveryLoading = createAction<boolean>('SET_CART_DELIVERY_LOADING');

export const closeMainMenu = createAction('CLOSE_MAIN_MENU');

export const openAccountMenu = createAction('OPEN_ACCOUNT_MENU');

export const closeAccountMenu = createAction('CLOSE_ACCOUNT_MENU');

export const setAppErrorToast = createAction<string | null>('SET_APP_ERROR_TOAST');

export const setAppSuccessToast = createAction<string | null>('SET_APP_SUCCESS_TOAST');

export const addCartPendingRequest = createAction<string>('ADD_CART_PENDING_REQUEST');

export const removeCartPendingRequest = createAction<string>('REMOVE_CART_PENDING_REQUEST');

export const setFaqKeyword = createAction<string>('SET_FAQ_KEYWORD');

export const setHelpCenterKeyword = createAction<string>('SET_HELP_CENTER_KEYWORD');

export const setHelpKeyword = createAction<string>('SET_HELP_KEYWORD');

export const setImpersonated = createAction<boolean>('SET_IMPERSONATED');

export const impersonateCustomer = createAction<{ connection_id: number; customer_id: number } | null>('IMPERSONATE_CUSTOMER');

export const setCostPlans = createAction<CostPlan[]>('SET_COST_PLANS');
