import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: 1180,
      paddingLeft: 30,
      height: '95vh',
      paddingRight: 30,
      '&>div': {
        maxHeight: '95vh',
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '90vw',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 320,
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    header: {
      paddingRight: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    supplierInfo: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      paddingRight: 10,
    },
    avatarWrap: {
      width: 67,
      height: 67,
      borderRadius: '50%',
      overflow: 'hidden',
      flexShrink: 0,
      marginRight: 15,
      boxShadow: `0 0 0 1px ${colorVariables.steelGrey}`,
    },
    supplierDetailsBox: {
      flex: 1,
      overflow: 'hidden',
    },
    supplierName: {
      flexGrow: 1,
      fontSize: 20,
      lineHeight: '22px',
      fontWeight: 700,
      marginBottom: 3,
    },
    supplierAddress: {
      fontSize: 13,
      lineHeight: '22px',
      color: colorVariables.grey60,
    },
    headActions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 30,
    },
    actionsTitle: {
      fontSize: 16,
      fontWeight: 600,
      marginRight: 'auto',
    },
    moreBtn: {
      marginLeft: 10,
      border: `2px solid ${colorVariables.grey40}`,
    },
    searchBox: {
      paddingTop: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 15,
    },
    searchWrap: {
      width: 300,
    },
    productsCount: {
      color: colorVariables.grey60,
      fontSize: 16,
    },
    tableHead: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: colorVariables.steelGrey15,
      height: 49,
      alignItems: 'center',
      padding: 10,
      borderRadius: 4,
    },
    tableContent: {
      height: 'calc(95vh - 368px)',
      overflowY: 'scroll',
    },
    sortTrigger: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        color: colorVariables.grey60,
        fontSize: 16,
      },
    },
    colHeadText: {
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
    },
    name: {
      width: '25%',
    },
    brand: {
      width: '11%',
    },
    id: {
      width: '10%',
    },
    unit: {
      width: '13%',
    },
    subCat: {
      width: '13%',
    },
    price: {
      width: '10%',
    },
    gst: {
      width: '5%',
      textAlign: 'center',
    },
    actions: {
      width: '3%',
    },
    dialog: {
      position: 'absolute',
      top: 40,
      right: 20,
    },
    addRow: {
      backgroundColor: 'rgba(207, 241, 255, 0.5)',
      padding: '14px 10px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    nameCell: {
      display: 'flex',
    },
    productImgBox: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 38,
      height: 38,
      borderRadius: '50%',
      marginRight: 10,
      backgroundColor: 'rgba(191, 201, 213, 0.6)',
      flexShrink: 0,
      cursor: 'pointer',
      overflow: 'hidden',
      '&:hover div': {
        visibility: 'visible',
      },
    },
    productImage: {
      backgroundColor: colorVariables.white,
    },
    editImage: {
      visibility: 'hidden',
      position: 'absolute',
      width: 38,
      height: 38,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(191, 201, 213, 0.6)',
      zIndex: 1,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    editIcon: {
      color: colorVariables.white,
      fontSize: 14,
    },
    input: {
      width: '100%',
      height: 22,
      marginTop: 8,
      border: `1px solid ${colorVariables.steelGrey}`,
      padding: '0 5px',
      lineHeight: '20px',
      marginRight: 10,
      fontSize: 14,
      '&:hover, &:focus': {
        border: `1px solid ${colorVariables.blue}`,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::placeholder': {
        color: colorVariables.grey60,
        fontSize: 12,
      },
      '&[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    priceCell: {
      textAlign: 'right',
    },
    checkBoxWrap: {
      transform: 'translateX(-20px)',
    },
    submitBtn: {
      width: 52,
      height: 24,
      padding: 0,
      transform: 'translate(-25px, 8px)',
    },
    loadingBox: {
      paddingTop: 100,
    },
  }),
);
