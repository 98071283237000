export const roundNumbers = (n: string | number | null | undefined) => {
  switch (typeof n) {
    case 'number':
      return Math.round(n * 100) / 100;
    case 'string':
      return Math.round(parseFloat(n) * 100) / 100;
    default:
      return 0;
  }
};
