import remove from 'lodash/remove';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import store from '../../store';
import { resetInventoryList } from '../../store/inventory';
import { setReplenishLoading } from '../../store/inventory/inventory.actions';
import { setAppSuccessToast } from '../../store/user';
import { getInventoryProductsQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  CreateShelfResponse,
  GetInventoriesResponse,
  GetInventoryListReqParams,
  GetInventoryStorages,
  GetRunOutProductsResponse,
  InventoryStoragesReq,
  MarkFavouriteRes,
  SetUpInventoryReq,
  SetUpInventoryResponse,
} from './types';

export const inventoryApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getInventories: builder.query<GetInventoriesResponse, GetInventoryListReqParams>({
      query: (args) => {
        const params = {
          ...getInventoryProductsQueryParamsHelper(args),
          page: args.page,
          per_page: 20,
        };
        return {
          url: `/inventories`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['Inventories'],
    }),

    getAllInventories: builder.query<GetInventoriesResponse, void>({
      query: (args) => {
        const params = {
          per_page: 1000,
        };
        return {
          url: `/inventories`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Inventories'],
    }),

    getRunningOutProducts: builder.query<GetRunOutProductsResponse, undefined>({
      query: () => {
        return {
          url: `/running_out`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Run_Out_Product'],
    }),

    getInventoryStorages: builder.query<GetInventoryStorages, void>({
      query: () => {
        return {
          url: `/inventory_storages`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Inventory_Storage'],
    }),

    getInventoryStorageProducts: builder.query<GetInventoryStorages, InventoryStoragesReq | void>({
      query: (arg) => {
        return {
          url: `/inventory_storages`,
          method: HTTP.GET,
          params: { 'q[storage_name]': arg?.name, recently_purchased: arg?.recently_purchased || undefined },
        };
      },
      providesTags: ['Inventory_Storage'],
      keepUnusedDataFor: 0,
    }),

    addInventoryStorage: builder.mutation<SuccessResponse, { name: string; skipInvalidation?: boolean }>({
      query: ({ name }) => {
        return {
          url: `/add_storage`,
          method: HTTP.POST,
          body: { name },
        };
      },
      invalidatesTags: (result, error, arg) => (arg.skipInvalidation ? [] : ['Inventory_Storage']),
    }),

    addInventoryShelf: builder.mutation<CreateShelfResponse, { name: string; storage_id: number }>({
      query: (body) => {
        return {
          url: `/add_shelf`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Inventory_Storage'],
    }),

    editInventory: builder.mutation<SetUpInventoryResponse, SetUpInventoryReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/inventories/${id}/setup_inventory`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Run_Out_Product'],
    }),

    autoFillToPar: builder.mutation<SuccessResponse, boolean>({
      query: (round) => {
        return {
          url: `/autofill_par`,
          method: HTTP.POST,
          body: {
            round,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setReplenishLoading(true));
          LocalStorageService.setItem('replenish_cart', true);
          await queryFulfilled;
          dispatch(setReplenishLoading(false));
        } catch (e) {
          LocalStorageService.clear('replenish_cart');
          dispatch(setReplenishLoading(false));
        }
      },
      invalidatesTags: ['Cart_Delivery'],
    }),

    favoriteInventory: builder.mutation<MarkFavouriteRes, number>({
      query: (id) => {
        return {
          url: `/inventories/${id}/favorite`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;
        const patchResult = dispatch(
          inventoryApi.util.updateQueryData(
            'getInventoryStorageProducts',
            {
              name: name || undefined,
              recently_purchased,
            },
            (draft) => {
              const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args);
              if (idx !== -1 && draft.storages[0]?.inventory_products[idx]) {
                draft.storages[0].inventory_products[idx].favourite = true;
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    unFavoriteInventory: builder.mutation<MarkFavouriteRes, number>({
      query: (id) => {
        return {
          url: `/inventories/${id}/unfavorite`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;
        const patchResult = dispatch(
          inventoryApi.util.updateQueryData(
            'getInventoryStorageProducts',
            {
              name: name || undefined,
              recently_purchased,
            },
            (draft) => {
              const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args);
              if (idx !== -1 && draft.storages[0]?.inventory_products[idx]) {
                draft.storages[0].inventory_products[idx].favourite = false;
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteInventory: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/inventories/${id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;
        const patchResult = dispatch(
          inventoryApi.util.updateQueryData(
            'getInventoryStorageProducts',
            {
              name: name || undefined,
              recently_purchased,
            },
            (draft) => {
              if (draft.storages[0]?.inventory_products) {
                remove(draft.storages[0]?.inventory_products, (el) => el.id === args);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast('Product has been deleted'));
        } catch {
          patchResult.undo();
        }
      },
    }),

    skipForeverInventory: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/inventories/${id}?skip_forever=true`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          inventoryApi.util.updateQueryData('getRunningOutProducts', undefined, (draft) => {
            remove(draft.items, (el) => el.id === args);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteInventoryShelf: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/delete_shelf`,
          method: HTTP.DELETE,
          body: {
            id,
          },
        };
      },
      async onQueryStarted(a, { dispatch, queryFulfilled }) {
        dispatch(resetInventoryList());
        await setSuccessToast(dispatch, queryFulfilled, 'Shelf has been deleted');
      },
      invalidatesTags: ['Inventory_Storage'],
    }),

    renameInventoryShelf: builder.mutation<SuccessResponse, { name: string; id: number }>({
      query: (body) => {
        return {
          url: `/rename_shelf`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(a, { dispatch, queryFulfilled }) {
        dispatch(resetInventoryList());
        await setSuccessToast(dispatch, queryFulfilled, 'Shelf name saved');
      },
      invalidatesTags: ['Inventory_Storage'],
    }),

    saveShelfPosition: builder.mutation<SuccessResponse, { [key: string]: number }>({
      query: (shelf_data) => {
        return {
          url: `/save_shelf_postion`,
          method: HTTP.PATCH,
          body: { shelf_data },
        };
      },
    }),

    saveProductPosition: builder.mutation<SuccessResponse, { [key: string]: number }>({
      query: (product_data) => {
        return {
          url: `/save_product_postion`,
          method: HTTP.PATCH,
          body: { product_data },
        };
      },
    }),

    setUpIncompletedInventory: builder.mutation<SetUpInventoryResponse, SetUpInventoryReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/inventories/${id}/setup_inventory`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          if (data?.inventory_product) {
            patchResult = dispatch(
              inventoryApi.util.updateQueryData('getRunningOutProducts', undefined, (draft) => {
                const idx = draft.items.findIndex((el) => el.id === args.id);
                if (idx !== -1) {
                  draft.items[idx] = { ...data.inventory_product };
                }
              }),
            );
          }
        } catch {
          patchResult?.undo();
        }
      },
    }),

    addProductToInventory: builder.mutation<SuccessResponse, number>({
      query: (product_id) => {
        return {
          url: `/inventories/add_product`,
          method: HTTP.POST,
          body: { product_id },
        };
      },
    }),

    removeProductFromInventory: builder.mutation<SuccessResponse, number>({
      query: (product_id) => {
        return {
          url: `/inventories/remove_product`,
          method: HTTP.POST,
          body: { product_id },
        };
      },
    }),

    saveInventoryProducts: builder.mutation<SuccessResponse, void>({
      query: () => {
        return {
          url: `/inventories/save_inventory_product`,
          method: HTTP.POST,
        };
      },
      invalidatesTags: ['Inventories', 'Run_Out_Product'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetInventoriesQuery,
  useGetAllInventoriesQuery,
  useGetRunningOutProductsQuery,
  useGetInventoryStoragesQuery,
  useAddInventoryStorageMutation,
  useAddInventoryShelfMutation,
  useEditInventoryMutation,
  useSetUpIncompletedInventoryMutation,
  useAutoFillToParMutation,
  useFavoriteInventoryMutation,
  useUnFavoriteInventoryMutation,
  useDeleteInventoryMutation,
  useGetInventoryStorageProductsQuery,
  useDeleteInventoryShelfMutation,
  useRenameInventoryShelfMutation,
  useSaveShelfPositionMutation,
  useSaveProductPositionMutation,
  useSkipForeverInventoryMutation,
  useAddProductToInventoryMutation,
  useRemoveProductFromInventoryMutation,
  useSaveInventoryProductsMutation,
  usePrefetch,
} = inventoryApi;
