import { getCountriesForTimezone } from 'countries-and-timezones';

import { countries } from '../constants/countries';
import { LocalStorageService } from '../services/localStorage.service';
import { getCurrentTimezone } from './getCurrentTimeZone';

export const detectCountry = async () => {
  const country = countries.find((el) => getCountriesForTimezone(getCurrentTimezone()).find((c) => c.id === el.value));
  const request = await fetch(
    `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEO_KEY}&fields=country_code2,country_name`,
  );
  const jsonResponse = (await request.json()) as { country_code2: string; country_name: string };
  LocalStorageService.setItem('country_by_ip', jsonResponse);
  if (!country || !jsonResponse.country_code2 || (country && jsonResponse.country_code2 && country.value !== jsonResponse.country_code2)) {
    return null;
  }
  return country;
};

export const getCoordinates = async (address: string) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    );
    const jsonRes = await response.json();
    const results = jsonRes.results;
    if (results[0]) {
      return results[0].geometry.location;
    } else {
      console.log('Address not found ');
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const parseAddressString = async (address: string) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    );
    const jsonRes = await response.json();
    const results = jsonRes.results;
    if (Array.isArray(results) && results.length > 0) {
      return results.filter((el: any) =>
        el.address_components?.find(
          (item: any) => item.types.includes('country') && item.short_name.toUpperCase() === LocalStorageService.getItem('country'),
        ),
      );
    } else {
      console.log('Address not found ');
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getAddressFromCoordinates = async (lat: number, lng: number) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=street_address|country&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    );
    const jsonRes = await response.json();
    const results = jsonRes.results;
    if (Array.isArray(results) && results.length) {
      return results;
    } else {
      console.log('Country not found or invalid API key.');
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};
