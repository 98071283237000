import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { colorVariables } from '../../../styles/colorVariables';
import { CloseButton } from '../close-button';
import { useComponentVisible } from './use-visible-hook';

import { StyleProps, useStyles } from './style';

interface Props extends StyleProps {
  isOpen?: boolean;
  triggerBtn?: React.ReactNode;
  clickOutsideUnTrackable?: boolean;
  onClose?: () => void;
  closeBtnShownOnMobile?: boolean;
}

export const CustomTooltip: React.FC<Props> = React.memo(
  ({ triggerBtn, isOpen, children, clickOutsideUnTrackable, onClose, right, arrowPosition, closeBtnShownOnMobile }) => {
    const classes = useStyles({ right, arrowPosition });
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleOpen = () => {
      setIsTooltipOpen(!isTooltipOpen);
    };

    const handleClose = () => {
      setIsTooltipOpen(false);
    };

    const { ref, isComponentVisible, triggerRef } = useComponentVisible(isTooltipOpen, handleClose);

    useEffect(() => {
      isOpen !== undefined && !clickOutsideUnTrackable && setIsTooltipOpen(isOpen);
    }, [isOpen]);

    return (
      <div>
        <div ref={triggerRef} onClick={handleOpen} className={classes.trigger}>
          {triggerBtn}
        </div>
        {(isOpen && clickOutsideUnTrackable) || (isComponentVisible && !clickOutsideUnTrackable) ? (
          <>
            <div className={classes.container} ref={ref}>
              <CloseButton
                onClick={onClose}
                color={colorVariables.grey60}
                size={20}
                customClass={clsx(classes.closeBtn, closeBtnShownOnMobile && classes.closeBtnShown)}
              />
              {children}
            </div>
            <div className={classes.overlay} onClick={onClose} />
          </>
        ) : null}
      </div>
    );
  },
);
