import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowBack } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { useJsApiLoader } from '@react-google-maps/api';
import clsx from 'clsx';
import { FastField, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import TagManager from 'react-gtm-module';
import ReactPlayer from 'react-player';
import * as Yup from 'yup';

import { useGetMyCompanyQuery, usePrefetch as usePrefetchCompany, useUpdateRestaurantProfileMutation } from '../../../api/company';
import { useMeQuery, useOnboardingMutation, useOnboardRestaurantMutation } from '../../../api/user';
import { Roles, SubscriptionPlanEnum } from '../../../api/user/types';
import Restaurant from '../../../assets/icons/Restaurant';
import Supplier from '../../../assets/icons/Supplier';
import Logo from '../../../assets/images/logo/Logo_White.svg';
import Map from '../../../assets/images/map.png';
import VideoImg from '../../../assets/images/onboardingVideoImg.png';
import { AddressAutocomplete } from '../../../shared/components/address-autocomplete';
import { BackArrowButton } from '../../../shared/components/back-arrrow-button';
import { BusinessPlaceAutocomplete } from '../../../shared/components/business-place-autocomplete';
import { ThemedButton } from '../../../shared/components/themed-button';
import { FormikInput } from '../../../shared/components/formik-input';
import { ImageUploadOnboarding } from '../../../shared/components/image-upload-onboarding';
import { NumberInput } from '../../../shared/components/number-input';
import { PostalCodePlaceAutocomplete } from '../../../shared/components/postal-code-place-autocomplete';
import { ProgressBar } from '../../../shared/components/progress-bar';
import { RadiusMap } from '../../../shared/components/radius-map';
import { ProductsCategories, productsCategoriesList } from '../../../shared/constants/products';
import { getCategoriesFromIds, getCategoryId } from '../../../shared/helpers/getCategoryId';
import { detectCountry } from '../../../shared/helpers/getCountry';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { ToastService } from '../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getIsImpersonated, getMySubscriptionType } from '../../../store/user';
import { backToAdminThunk } from '../../../store/user/user.actions';
import { CountryModal } from './country-modal';

import { useStyles } from './style';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';

export enum OnBoardSteps {
  ROLES = 'ROLES',
  BUSINESS = 'BUSINESS',
  DELIVERY = 'DELIVERY',
  PRODUCTS = 'PRODUCTS',
  SUPPLIERS = 'SUPPLIERS',
  SUPPLIER_TYPE = 'SUPPLIER_TYPE',
  LOGO = 'LOGO',
  VIDEO = 'VIDEO',
  MANUAL_INFO = 'MANUAL_INFO',
  MANUAL_UPLOAD = 'MANUAL_UPLOAD',
}

interface Values {
  business: string;
  address: string;
  address_1?: string;
  address_2?: string;
  suburb: string;
  state: string;
  postcode?: string;
  country?: string;
}

const initialValues: Values = {
  business: '',
  address: '',
  suburb: '',
  state: '',
};

interface Props {
  deferredPromptRef: any;
}

const OnBoarding: React.FC<Props> = ({ deferredPromptRef }) => {
  const classes = useStyles();
  const { isMobile, isDesktop } = useScreenSize();
  const { replace } = useHistory();
  const dispatch = useAppDispatch();
  const vBtnRef = useRef<HTMLImageElement | null>(null);
  const { logoUrl } = useCurrentTheme();

  const [role, setRole] = useState<Roles | null>(LocalStorageService.getItem('onBoardRole') || null);
  const [onBoardStep, setOnBoardStep] = useState<OnBoardSteps | null>(LocalStorageService.getItem('onBoardStep') || OnBoardSteps.ROLES);
  const [isAddressOpened, setIsAddressOpened] = useState(false);
  const [isFormOpened, setIsFormOpened] = useState(LocalStorageService.getItem('isFormOpened') || false);
  const [formData, setFormData] = useState<Values>(LocalStorageService.getItem('onboardData') || initialValues);
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(LocalStorageService.getItem('onBoardLocation') || null);
  const [deliveryRadius, setDeliveryRadius] = useState<number | null>(LocalStorageService.getItem('onBoardDeliveryRadius') || null);
  const [categories, setCategories] = useState<ProductsCategories[]>(LocalStorageService.getItem('onBoardCategories') || []);
  // const [currentCategoryIdx, setCurrentCategoryIdx] = useState<number>(0);
  const [logo, setLogo] = useState<Blob | null>(null);
  const [isCountryModalShown, setIsCountryModalShown] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const isRestaurant = role === Roles.CUSTOMER;
  const plan = useAppSelector(getMySubscriptionType);

  const { data: user } = useMeQuery();
  const { data: company } = useGetMyCompanyQuery(user?.company?.id, {
    skip: !user?.company?.id,
  });

  const [onboard, { isLoading, isSuccess }] = useOnboardingMutation();
  const [onboardRestaurant, { isLoading: onBoardRestaurantLoading, isSuccess: onBoardRestaurantSuccess }] = useOnboardRestaurantMutation();
  const prefetchConnectionRequests = usePrefetchCompany('getConnectionRequests');
  const impersonated = useAppSelector(getIsImpersonated);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ['places'],
  });

  // const { data: connectionRequests = [] } = useGetConnectionRequestsQuery();
  const [updateProfile, { isLoading: updateLoading }] = useUpdateRestaurantProfileMutation();

  const businessSchema = Yup.object().shape({
    business: Yup.string().required('Required field'),
    address: Yup.string().required('Required field'),
    suburb: Yup.string().required('Required field'),
  });

  const onBackToAdmin = () => {
    dispatch(backToAdminThunk());
  };

  const checkIsBusinessStepCompleted = (values: Values) => {
    return values.business && values.address && values.postcode;
  };

  const setBusinessStep = () => {
    setOnBoardStep(OnBoardSteps.BUSINESS);
  };

  const backToRolesStep = () => {
    setOnBoardStep(OnBoardSteps.ROLES);
    setFormData(initialValues);
    setIsAddressOpened(false);
    setIsFormOpened(false);
    setLocation(null);
    setCategories([]);
  };

  const openDeliveryStep = () => {
    setOnBoardStep(OnBoardSteps.DELIVERY);
  };

  const backToBusinessStep = () => {
    setBusinessStep();
  };

  const backToDeliveryStep = () => {
    openDeliveryStep();
  };

  const openProductsStep = () => {
    setOnBoardStep(OnBoardSteps.PRODUCTS);
    // setCurrentCategoryIdx(0);
  };

  /*  const openSupplierTypeStep = () => {
    setOnBoardStep(OnBoardSteps.SUPPLIER_TYPE);
  };

  const openSuppliersStep = () => {
    setOnBoardStep(OnBoardSteps.SUPPLIERS);
  };*/

  const openLogoStep = () => {
    setOnBoardStep(OnBoardSteps.LOGO);
  };

  const backToProducts = () => {
    openProductsStep();
    setLogo(null);
  };

  /*  const backToSupplierType = () => {
    setOnBoardStep(OnBoardSteps.SUPPLIER_TYPE);
  };*/

  const setDetectedCountry = async () => {
    if (LocalStorageService.getItem('country')) {
      return;
    }
    const countryRes = await detectCountry();
    if (!countryRes) {
      setIsCountryModalShown(true);
    } else {
      LocalStorageService.setItem('country', countryRes.value);
    }
  };

  const calculateProgress = (step: OnBoardSteps | null) => {
    if (step === OnBoardSteps.ROLES) {
      return 15;
    }
    if (step === OnBoardSteps.BUSINESS) {
      return 25;
    }
    if (step === OnBoardSteps.DELIVERY) {
      return 40;
    }
    if (step === OnBoardSteps.PRODUCTS) {
      return 60;
    }
    if (step === OnBoardSteps.LOGO && logo) {
      return 100;
    }
    if (step === OnBoardSteps.LOGO) {
      return 80;
    }
    if (step === OnBoardSteps.SUPPLIER_TYPE) {
      return 70;
    }
    if (step === OnBoardSteps.SUPPLIERS) {
      return 80;
    }
    if (step === OnBoardSteps.MANUAL_INFO) {
      return 85;
    }
    if (step === OnBoardSteps.MANUAL_UPLOAD) {
      return 90;
    }
    return 0;
  };

  const setBusiness = (place: google.maps.places.PlaceResult) => {
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    const business = place.name || '';
    const country = place.address_components?.find((el) => el.types.includes('country'))?.long_name || '';
    const state = place.address_components?.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || '';
    const suburb = place.address_components?.find((el) => el.types.includes('locality'))?.long_name || '';
    const address = place.formatted_address || '';
    const postcode = place.address_components?.find((el) => el.types.includes('postal_code'))?.long_name || '';
    const street_number = place.address_components?.find((el) => el.types.includes('street_number'))?.long_name || '';
    const route = place.address_components?.find((el) => el.types.includes('route'))?.long_name || '';
    setFormData((prev) => ({
      ...prev,
      business,
      state,
      postcode,
      address,
      suburb,
      country,
      address_1: `${street_number} ${route}`.trim(),
    }));
    lat && lng && setLocation({ lat, lng });
  };

  const handleDeliveryRadiusChange = (value: number) => {
    setDeliveryRadius(value);
  };

  const setAddress = (place: google.maps.places.PlaceResult) => {
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    const country = place.address_components?.find((el) => el.types.includes('country'))?.long_name || '';
    const state = place.address_components?.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || '';
    const suburb = place.address_components?.find((el) => el.types.includes('locality'))?.long_name || '';
    const address = place.formatted_address || '';
    const postcode = place.address_components?.find((el) => el.types.includes('postal_code'))?.long_name || '';
    const street_number = place.address_components?.find((el) => el.types.includes('street_number'))?.long_name || '';
    const route = place.address_components?.find((el) => el.types.includes('route'))?.long_name || '';
    setFormData((prev) => ({
      ...prev,
      state,
      postcode,
      address,
      suburb,
      country,
      address_1: `${street_number} ${route}`.trim(),
    }));
    lat && lng && setLocation({ lat, lng });
  };

  const onSetPostCodeLoc = (place: google.maps.places.PlaceResult, values: Values) => {
    const postcode = place.address_components?.find((el) => el.types.includes('postal_code'))?.long_name || '';
    if (!postcode) {
      return;
    }
    const country = place.address_components?.find((el) => el.types.includes('country'))?.long_name || '';
    const state = place.address_components?.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || '';
    const suburb = place.address_components?.find((el) => el.types.includes('locality'))?.long_name || '';
    setFormData((prev) => ({ ...prev, ...values, postcode, state, country, suburb }));
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    lat && lng && setLocation({ lat, lng });
  };

  const openAddressForm = () => {
    setIsFormOpened(true);
    setFormData((prev) => ({
      ...initialValues,
      business: prev.business,
      address: prev.address,
    }));
  };

  const openAddressField = () => {
    setIsAddressOpened(true);
    setFormData((prev) => ({
      ...initialValues,
      business: prev.business,
    }));
  };

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    formikHelpers.setSubmitting(false);
    setFormData((prev) => ({ ...prev, ...values, state: prev.state }));
    !isRestaurant ? openDeliveryStep() : openProductsStep();
    formikHelpers.resetForm();
  };

  const onSetCategory = (category: ProductsCategories) => {
    setCategories((prev) => {
      return prev.includes(category)
        ? prev.filter((item) => item !== category)
        : productsCategoriesList.reduce((acc: ProductsCategories[], el) => {
            if ([...prev, category].includes(el.title)) {
              acc.push(el.title);
            }
            return acc;
          }, []);
    });
  };

  const setRoleHandler = (selectedRole: Roles) => {
    setRole(selectedRole);
    setBusinessStep();
  };

  const onUpdateCategories = () => {
    const categoriesToAdd = categories
      .filter((category) => !company?.company_categories.some((cc) => cc.category_id === getCategoryId(category)))
      .map((cat) => ({ category_id: getCategoryId(cat) }));
    const categoriesToRemove =
      company?.company_categories
        .filter((cc) => !categories.some((category) => cc.category_id === getCategoryId(category as ProductsCategories)))
        .map((cc) => ({
          _destroy: true,
          category_id: cc.category_id,
          id: cc.id,
        })) || [];

    company &&
      updateProfile({
        id: company.id,
        company: {
          users_attributes: {
            id: user?.id,
          },
          company_categories_attributes: [...categoriesToAdd, ...categoriesToRemove],
        },
      }).then(() => {
        // openSupplierTypeStep();
      });
  };

  const finishOnBoarding = () => {
    /*    if (isRestaurant && company?.id) {
      onUpdateCategories();
      return;
    }*/
    if (checkIsBusinessStepCompleted(formData) && role) {
      const reqBody = new FormData();
      reqBody.append('company[name]', formData.business);
      reqBody.append('company[company_type]', role);
      deliveryRadius && !isRestaurant && reqBody.append('delivery_radiuses_attributes[delivery_radius]', deliveryRadius.toString());
      logo && reqBody.append('company[picture]', logo);

      categories.forEach((cat, idx) => {
        reqBody.append(
          `company[company_categories_attributes][${(company?.company_categories.length || 0) + idx}][category_id]`,
          getCategoryId(cat).toString(),
        );
      });
      user?.company_addresses?.length && reqBody.append('company[addresses_attributes][0][id]', user.company_addresses[0].id.toString());
      reqBody.append('company[addresses_attributes][0][city]', formData.suburb);
      reqBody.append('company[addresses_attributes][0][full_address]', formData.address || ' ');
      reqBody.append('company[addresses_attributes][0][postcode]', formData.postcode || ' ');
      reqBody.append('company[addresses_attributes][0][state]', formData.state || ' ');
      reqBody.append('company[addresses_attributes][0][country]', formData.country || '');
      formData.address_1 && reqBody.append('company[addresses_attributes][0][street_address1]', formData.address_1);
      onboard(reqBody).then((res) => {
        if ('data' in res && res.data?.success && isRestaurant) {
          onOnboardRestaurant();
        }
      });
    } else {
      ToastService.error('Address data not found');
    }
  };

  /*  const decrementCatIdx = () => {
    setCurrentCategoryIdx((prev) => prev - 1);
  };

  const incrementCatIdx = () => {
    setCurrentCategoryIdx((prev) => prev + 1);
  };*/

  const onOnboardRestaurant = () => {
    if (!onBoardRestaurantLoading) {
      onboardRestaurant();
      LocalStorageService.clear('manual_supplier_id');
    }
  };

  useEffect(() => {
    if (isSuccess && !isRestaurant) {
      !isDesktop && deferredPromptRef?.current?.prompt();
      replace('/dashboard', { showInfo: true });
    }
    if (isSuccess && isRestaurant) {
      // openSupplierTypeStep();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (onBoardRestaurantSuccess && isRestaurant) {
      !isDesktop && deferredPromptRef?.current?.prompt();
      replace('/browse_by_supplier', { showInfo: true });
    }
  }, [onBoardRestaurantSuccess]);

  useEffect(() => {
    if (role === Roles.CUSTOMER) {
      prefetchConnectionRequests();
    }
    setCategories(LocalStorageService.getItem('onBoardCategories') || productsCategoriesList.map((el) => el.title));
  }, [role]);

  useEffect(() => {
    document.body.scrollIntoView({ block: 'start' });
    if (role && user) {
      TagManager.dataLayer({
        dataLayer: {
          userType: role,
          userId: user.id,
        },
      });
    }
  }, [role, onBoardStep]);

  useEffect(() => {
    if (plan === SubscriptionPlanEnum.MENU || plan === SubscriptionPlanEnum.HOSPO) {
      setRole(Roles.CUSTOMER);
      setOnBoardStep(LocalStorageService.getItem('onBoardStep') || OnBoardSteps.VIDEO);
      const timer = setTimeout(() => {
        vBtnRef?.current?.click();
        clearTimeout(timer);
      }, 2000);
      return;
    }
    if (user?.company?.company_type) {
      setRole(user?.company?.company_type);
      setOnBoardStep(LocalStorageService.getItem('onBoardStep') || OnBoardSteps.BUSINESS);
    }
  }, [user?.company?.company_type, plan]);

  useEffect(() => {
    if (!formData.postcode && company?.name) {
      ToastService.warning('Please select your company from the dropdown or enter address details');
    }
  }, [!!company?.name, !!formData.postcode]);

  useEffect(() => {
    setDetectedCountry();
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      deferredPromptRef.current = e;
    });
  }, []);

  useEffect(() => {
    LocalStorageService.setItem('onboardData', formData);
    LocalStorageService.setItem('isFormOpened', isFormOpened);
    LocalStorageService.setItem('onBoardStep', onBoardStep);
    LocalStorageService.setItem('onBoardRole', role);
    LocalStorageService.setItem('onBoardLocation', location);
    LocalStorageService.setItem('onBoardDeliveryRadius', deliveryRadius);
  }, [formData, isFormOpened, onBoardStep, role, location, deliveryRadius]);

  useEffect(() => {
    LocalStorageService.setItem('onBoardCategories', categories);
  }, [categories]);

  useEffect(() => {
    if (company?.name) {
      setFormData((prev) => ({ ...prev, business: company?.name }));
    }
    if (company?.categories?.length) {
      setCategories(getCategoriesFromIds(company.categories));
    }
    if (company?.addresses[0] && company?.addresses[0]?.latitude && company?.addresses[0]?.longitude) {
      setLocation({ lat: company?.addresses[0]?.latitude, lng: company?.addresses[0]?.longitude });
    }
  }, [company]);

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <div className={classes.logoBox}>
          <img src={logoUrl || Logo} alt='OpenPantry' className={classes.logo} style={{ objectFit: 'contain', maxHeight: 70 }} />
        </div>
        <div className={classes.progressWrap}>
          <ProgressBar percent={calculateProgress(onBoardStep)} />
        </div>
        {onBoardStep === OnBoardSteps.ROLES && (
          <>
            <div className={classes.title}>
              Hey {user?.first_name || ''}, welcome <br />
              to Open Pantry
            </div>
            <div className={classes.subTitle}>Let’s set up your account, it will take just a few minutes</div>
            <div className={classes.boldText}>Are you a....</div>
            <div className={classes.rolesBox}>
              <div className={classes.roleItem} onClick={setRoleHandler.bind(null, Roles.CUSTOMER)}>
                <Restaurant width={64} height={64} />
                <div className={classes.roleText}>Restaurant</div>
              </div>
              <div className={classes.roleItem} onClick={setRoleHandler.bind(null, Roles.SUPPLIER)}>
                <Supplier width={64} height={64} />
                <div className={classes.roleText}>Wholesale Supplier</div>
              </div>
            </div>
          </>
        )}
        {onBoardStep === OnBoardSteps.VIDEO && isRestaurant && (
          <>
            <div className={classes.title}>
              Hey {user?.first_name || ''}, welcome <br />
              to Open Pantry
            </div>
            <div className={classes.subTitle}>
              Congratulations! You have taken the first step in managing your restaurants costs and boosting profits. <br /> Please watch
              the short introduction video of how to get the most out of your account
            </div>
            {!isPlaying ? (
              <img
                ref={vBtnRef}
                src={VideoImg}
                alt='Menu costing video'
                onClick={setIsPlaying.bind(null, true)}
                width={isMobile ? 320 : 640}
              />
            ) : (
              <ReactPlayer
                url={`https://www.youtube.com/watch?v=l0B1qMX0s2c?autoplay=1`}
                controls={true}
                width={isMobile ? 320 : 640}
                height={isMobile ? 180 : undefined}
                playing={isPlaying}
                stopOnUnmount={true}
                muted={false}
                autoplay={true}
              />
            )}
            <div className={clsx(classes.btnBox, classes.btnBoxVideo)}>
              <div />
              <ThemedButton onClick={setBusinessStep} title='Next' width={isMobile ? 150 : 215} />
              <div />
            </div>
          </>
        )}
        {onBoardStep === OnBoardSteps.BUSINESS && (
          <>
            {isFormOpened ? (
              <Formik initialValues={formData} onSubmit={onSubmit} validationSchema={businessSchema} enableReinitialize={true}>
                {({ submitForm, isValid, setFieldError, values }) => (
                  <Form className={classes.formBox}>
                    <div className={classes.title}>
                      Enter your <br /> business address
                    </div>
                    <div className={classes.fieldWrap}>
                      <Field name='business'>
                        {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Business Name' placeholder='Business Name' />}
                      </Field>
                    </div>
                    <div className={classes.fieldWrap}>
                      <Field name='address'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput {...fieldProps} label='Address Line 1' placeholder='Address Line 1' autoComplete='chrome-off' />
                        )}
                      </Field>
                    </div>
                    <div className={classes.fieldWrap}>
                      <Field name='address_1'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput {...fieldProps} label='Address Line 2' placeholder='Address Line 2' autoComplete='chrome-off' />
                        )}
                      </Field>
                    </div>
                    <div className={classes.smallFieldsBox}>
                      <div className={classes.smallFieldWrap}>
                        {isLoaded && (
                          <PostalCodePlaceAutocomplete
                            setFieldError={setFieldError}
                            placeholder='Postcodе'
                            setPlace={(place) => {
                              onSetPostCodeLoc(place, values);
                            }}
                            label='Postcodе'
                            initialValue={formData.postcode}
                          />
                        )}
                      </div>
                      <div className={classes.smallFieldWrap}>
                        <FastField name='suburb'>
                          {(fieldProps: FieldProps) => <FormikInput placeholder='Suburb' label='Suburb' {...fieldProps} />}
                        </FastField>
                      </div>
                      <div className={classes.smallFieldWrap}>
                        <FastField name='state'>
                          {(fieldProps: FieldProps) => <FormikInput placeholder='State/City' label='State/City' {...fieldProps} />}
                        </FastField>
                      </div>
                    </div>
                    <div className={classes.btnBox}>
                      {!user?.company?.company_type ? (
                        <BackArrowButton
                          onClick={
                            plan === SubscriptionPlanEnum.MENU || plan === SubscriptionPlanEnum.HOSPO
                              ? setOnBoardStep.bind(null, OnBoardSteps.VIDEO)
                              : backToRolesStep
                          }
                        />
                      ) : (
                        <div />
                      )}
                      <ThemedButton
                        disabled={!isValid || !formData.postcode}
                        onClick={submitForm}
                        title='Next'
                        width={isMobile ? 150 : 215}
                      />
                      <div />
                    </div>
                    <input type='text' name='postcode' placeholder='postcode' className='hiddenInput' />
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                <div className={classes.title}>
                  {isAddressOpened ? (
                    <span>
                      Enter your business,
                      <br /> name & address
                    </span>
                  ) : (
                    <span>
                      Tell us a little more
                      <br /> about your business
                    </span>
                  )}
                </div>
                {isLoaded && (
                  <div className={classes.fieldWrap}>
                    <BusinessPlaceAutocomplete
                      placeholder='Search for your business...'
                      setPlace={setBusiness}
                      label='Business Name'
                      initialValue={formData.business}
                    />
                  </div>
                )}
                {isAddressOpened && isLoaded && (
                  <div className={classes.fieldWrap}>
                    <AddressAutocomplete
                      placeholder='Start typing your address...'
                      setPlace={setAddress}
                      label='Address'
                      initialValue={formData.address_1}
                    />
                  </div>
                )}
                <div className={classes.notFoundText} onClick={isAddressOpened ? openAddressForm : openAddressField}>
                  {isAddressOpened ? 'I can’t find my address' : 'I can’t find my business'}
                </div>
              </>
            )}
            {!isFormOpened && (
              <div className={classes.btnBox}>
                {!user?.company?.company_type ? (
                  <BackArrowButton
                    onClick={
                      plan === SubscriptionPlanEnum.MENU || plan === SubscriptionPlanEnum.HOSPO
                        ? setOnBoardStep.bind(null, OnBoardSteps.VIDEO)
                        : backToRolesStep
                    }
                  />
                ) : (
                  <div />
                )}
                <ThemedButton
                  onClick={!isRestaurant ? openDeliveryStep : openProductsStep}
                  title='Next'
                  width={isMobile ? 150 : 215}
                  disabled={!checkIsBusinessStepCompleted(formData)}
                />
                <div />
              </div>
            )}
          </>
        )}
        {onBoardStep === OnBoardSteps.DELIVERY && (
          <>
            <div className={classes.title}>How far do you deliver?</div>
            <div className={classes.subTitle}>How far away is your furtherest customer? Don’t worry you can edit this later</div>
            {location && isLoaded ? (
              <div className={classes.mapWrap}>
                <RadiusMap
                  radius={deliveryRadius ?? 0}
                  lat={location?.lat}
                  lng={location?.lng}
                  markerTitle={formData.business || undefined}
                />
              </div>
            ) : (
              <img src={Map} alt='map' className={classes.mapImg} />
            )}
            <div className={classes.deliveryInputBox}>
              <div className={classes.deliveryLabel}>Your delivery radius (Km)</div>
              <NumberInput onChange={handleDeliveryRadiusChange} value={deliveryRadius || 0} />
            </div>
            <div className={classes.btnBox}>
              <BackArrowButton onClick={backToBusinessStep} />
              <ThemedButton onClick={openProductsStep} title='Next' width={isMobile ? 150 : 215} />
              <div />
            </div>
            <div className={clsx([classes.notFoundText, classes.addLater])} onClick={openProductsStep}>
              I’ll add this later
            </div>
          </>
        )}
        {onBoardStep === OnBoardSteps.PRODUCTS && (
          <>
            <div className={classes.title}>{!isRestaurant ? 'What do you sell?' : 'What do you normally order?'}</div>
            <div className={classes.subTitle}>
              We recommend having all categories selected. <br /> Select all that apply and don’t worry you can change this later.
            </div>
            <div className={classes.productsBox}>
              {productsCategoriesList.map(({ title, icon }) => (
                <div
                  key={title}
                  className={clsx({
                    [classes.productItem]: true,
                    [classes.productItemSelected]: categories.includes(title),
                  })}
                  onClick={onSetCategory.bind(null, title)}
                >
                  <div className={classes.productIcon}>{icon}</div>
                  {title}
                  <CheckIcon className={classes.checkIcon} />
                </div>
              ))}
            </div>
            <div className={classes.btnBox}>
              <BackArrowButton onClick={!isRestaurant ? backToDeliveryStep : backToBusinessStep} />
              <ThemedButton
                onClick={!isRestaurant ? openLogoStep : finishOnBoarding}
                title='Next'
                width={isMobile ? 150 : 215}
                disabled={isLoading || updateLoading || onBoardRestaurantLoading}
              />
              <div />
            </div>
          </>
        )}
        {onBoardStep === OnBoardSteps.LOGO && !isRestaurant && (
          <>
            <div className={classes.title}>Upload your logo</div>
            <div className={clsx(classes.subTitle, classes.uploadSubTitle)}>
              {isDesktop
                ? 'Please upload your logo by dragging and dropping or press choose a file'
                : `Tap icon to upload logo, and don’t worry you can change this later.`}
            </div>
            <ImageUploadOnboarding saveImage={setLogo} clearLogo={setLogo.bind(null, null)} />
            <div className={classes.btnBox}>
              <BackArrowButton onClick={backToProducts} />
              <ThemedButton onClick={finishOnBoarding} title='Next' width={isMobile ? 150 : 215} disabled={isLoading || !logo} />
              <div />
            </div>
            <div className={classes.notFoundText} onClick={finishOnBoarding}>
              Skip
            </div>
          </>
        )}
        {/*        {onBoardStep === OnBoardSteps.SUPPLIER_TYPE && isRestaurant && (
          <>
            <div className={clsx(classes.title, classes.supplierPageTitle)}>
              Two ways to manage your <br /> products & prices:
            </div>
            <div className={classes.supplierTypeWrap}>
              <div className={classes.recWrap}>
                <div className={classes.supplierTypeTile}>
                  <SvgCarIcon />
                  <span>Supplier Managed</span>
                </div>
                <div className={classes.recommendedLabel}>Recommended</div>
              </div>

              <div>OR</div>
              <div className={classes.supplierTypeTile}>
                <SvgShopIcon />
                <span>
                  Self <br />
                  Managed
                </span>
              </div>
            </div>
            <div className={classes.supplierTypeText}>
              At Open Pantry we offer you two ways to manage your products & pricing. You can select manage your pricing your self, or you
              can invite your suppliers to manage your products & prices themselves.
            </div>
            <div className={clsx(classes.btnBox)}>
              <BackArrowButton onClick={backToProducts} />
              <ThemedButton onClick={openSuppliersStep} title='Next' width={isMobile ? 150 : 215} />
              <div />
            </div>
          </>
        )}
        {onBoardStep === OnBoardSteps.SUPPLIERS && isRestaurant && (
          <>
            <div className={clsx(classes.title, classes.supplierPageTitle)}>Lets add your suppliers</div>
            <div className={classes.suppliersCountBox}>
              <span className={classes.categoriesNumber}>{categories.length}</span> <span>Suppliers</span>
            </div>

            <div className={classes.categorySlider}>
              <svg
                width='20'
                height='46'
                viewBox='0 0 20 46'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={currentCategoryIdx !== 0 ? decrementCatIdx : setCurrentCategoryIdx.bind(null, categories.length - 1)}
              >
                <path
                  d='M18 44.5L4.29948 28.1355C1.81156 25.1638 1.81156 20.8362 4.29948 17.8645L18 1.5'
                  stroke='#51D9B0'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
              </svg>
              <div className={clsx(classes.categoryTile)}>
                <div className={classes.categoryIcon} id='cat-tile'>
                  {productsCategoriesList.find((el) => el.title === categories[currentCategoryIdx])?.lightIcon}
                </div>
                <div className={classes.catTitle}>{categories[currentCategoryIdx]}</div>
              </div>
              <svg
                width='18'
                height='44'
                viewBox='0 0 18 44'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={currentCategoryIdx !== categories.length - 1 ? incrementCatIdx : setCurrentCategoryIdx.bind(null, 0)}
              >
                <path
                  d='M2 1.5L14.1583 17.0778C16.4163 19.9709 16.4163 24.0291 14.1583 26.9222L2 42.5'
                  stroke='#51D9B0'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
              </svg>
            </div>
            <div className={classes.indicatorsBox}>
              {categories.map((el, index) =>
                connectionRequests.some((cr) => cr.connection_categories[0]?.category_id === getCategoryId(el)) ? (
                  <svg
                    width={index === currentCategoryIdx ? '37' : '18'}
                    height={index === currentCategoryIdx ? '37' : '18'}
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z'
                      fill='#51D9B0'
                    />
                    <path d='M7.5 12L4.5 9' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    <path d='M13.5 6L7.5 12' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                  </svg>
                ) : (
                  <div key={el} className={clsx(classes.indicatorItem, index === currentCategoryIdx && classes.indicatorItemActive)} />
                ),
              )}
            </div>
            <AddSuppliersNew
              categories={categories}
              location={location}
              category={categories[currentCategoryIdx]}
              openNextCategory={currentCategoryIdx !== categories.length - 1 ? incrementCatIdx : onOnboardRestaurant}
              backHandler={backToSupplierType}
              onOnboardRestaurant={onOnboardRestaurant}
              setManualSupplier={setOnBoardStep.bind(null, OnBoardSteps.MANUAL_INFO)}
            />
            <div
              className={clsx([classes.notFoundText, classes.addLaterSuppliers])}
              onClick={currentCategoryIdx !== categories.length - 1 ? incrementCatIdx : onOnboardRestaurant}
            >
              Skip
            </div>
          </>
        )}
        {onBoardStep === OnBoardSteps.MANUAL_INFO && isRestaurant && (
          <OnboardManualSupplierNew setOnboardStep={(step) => setOnBoardStep(step)} />
        )}
        {onBoardStep === OnBoardSteps.MANUAL_UPLOAD && isRestaurant && (
          <OnboardManualSupplierTemplate
            setOnboardStep={(step) => setOnBoardStep(step)}
            openNextCategory={currentCategoryIdx !== categories.length - 1 ? incrementCatIdx : onOnboardRestaurant}
          />
        )}*/}
      </div>
      <CountryModal isOpen={isCountryModalShown} closeModal={setIsCountryModalShown.bind(null, false)} />
      {impersonated && (
        <ThemedButton
          onClick={onBackToAdmin}
          title='Return to admin'
          buttonStyle='blueButton'
          isSmall={true}
          startIcon={<ArrowBack />}
          customClass={classes.backToAdmin}
          width={180}
        />
      )}
    </div>
  );
};

export default OnBoarding;
