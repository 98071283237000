import React from 'react';

import { ManualProductPreview } from '../../../../api/product/types';
import { formatCurrency } from '../../../../shared/helpers/format-currency';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';

import { useStyles } from './style';

interface Props {
  data?: ManualProductPreview[];
}

export const ImportManualProductPreview: React.FC<Props> = React.memo(({ data = [] }) => {
  const classes = useStyles();
  const { isDesktop, isTabletLandscape } = useScreenSize();

  return isDesktop || isTabletLandscape ? (
    <div className={classes.root}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>
              <div className={classes.thWrap}>
                <span>ID</span>
              </div>
            </th>
            <th>
              <div className={classes.thWrap}>
                <span>Product Name</span>
              </div>
            </th>
            <th>
              <div className={classes.thWrap}>
                <span>Brand</span>
              </div>
            </th>
            <th>
              <div className={classes.thWrap}>
                <span>Unit</span>
              </div>
            </th>
            <th>
              <div className={classes.thWrap}>
                <span>TAX</span>
              </div>
            </th>
            <th>
              <div className={classes.thWrap}>
                <span>Pricing</span>
              </div>
            </th>
            <th>
              <div className={classes.thWrap}>
                <span>Sub Category</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, idx) => (
            <tr key={idx}>
              <td>
                <div className={classes.cell}> {row.product_id}</div>
              </td>
              <td>
                <div className={classes.nameCell}>{row.name}</div>
              </td>
              <td>
                <div className={classes.cell}>{row.brand}</div>
              </td>
              <td>
                <div className={classes.cell}>{row.unit}</div>
              </td>
              <td>
                <div className={classes.cell}>{row.gst ? 'YES' : 'NO'}</div>
              </td>
              <td>
                <div className={classes.cell}>{formatCurrency(row.price_per_unit || 0)}</div>
              </td>
              <td>
                <div className={classes.cell}>{row.sub_category}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div className={classes.previewInputStageBox}>
      {data.map((row, idx) => (
        <div key={idx}>
          <div className={classes.previewInputStageRow}>
            <div className={classes.previewItemTitle}>Product ID</div>
            <div className={classes.previewItemText}>{row.product_id}</div>
          </div>
          <div className={classes.previewInputStageRow}>
            <div className={classes.previewItemTitle}>Product Name</div>
            <div className={classes.previewItemText}>{row.name}</div>
          </div>
          <div className={classes.previewInputStageRow}>
            <div className={classes.previewItemTitle}>Brand</div>
            <div className={classes.previewItemText}>{row.brand}</div>
          </div>
          <div className={classes.previewInputStageRow}>
            <div className={classes.previewItemTitle}>Unit of Measure</div>
            <div className={classes.previewItemText}>{row.unit}</div>
          </div>
          <div className={classes.previewInputStageRow}>
            <div className={classes.previewItemTitle}>Tax</div>
            <div className={classes.previewItemText}>{row.gst ? 'YES' : 'NO'}</div>
          </div>
          <div className={classes.previewInputStageRow}>
            <div className={classes.previewItemTitle}>Pricing</div>
            <div className={classes.previewItemText}>{formatCurrency(row.price_per_unit || 0)}</div>
          </div>
          <div className={classes.previewInputStageRow}>
            <div className={classes.previewItemTitle}>Sub Category</div>
            <div className={classes.previewItemText}>{row.sub_category}</div>
          </div>
        </div>
      ))}
    </div>
  );
});
