import { CountryCurrencyInfo } from '../../api/admin-countries/types';
import store from '../../store';
import { countries, euCountriesList } from '../constants/countries';
import { LocalStorageService } from '../services/localStorage.service';
import { SessionStorageService } from '../services/sessionStorage.service';

export const formatCurrency = (value: string | number, maxFraction?: number) => {
  const countryCode = LocalStorageService.getItem('country');
  const fullCountryName = countries.find((el) => el.value === countryCode)?.label;

  const countryInfo = SessionStorageService.getItem('country_currency') as CountryCurrencyInfo | null;
  const countryCurrency = countryInfo?.sub_countries?.find((el) => el.name === fullCountryName)?.currency || countryInfo?.currency;

  if (euCountriesList.some((el) => el.value === countryCode)) {
    switch (countryCode) {
      case 'BG': {
        return new Intl.NumberFormat('bg-BG', {
          style: 'currency',
          currency: countryCurrency || 'BGN',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        }).format(+value);
      }
      case 'HR': {
        return new Intl.NumberFormat('hr-HR', {
          style: 'currency',
          currency: countryCurrency || 'HRK',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        }).format(+value);
      }
      case 'CZ': {
        return new Intl.NumberFormat('cs-CZ', {
          style: 'currency',
          currency: countryCurrency || 'CZK',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        }).format(+value);
      }
      case 'DK': {
        return new Intl.NumberFormat('da-DK', {
          style: 'currency',
          currency: countryCurrency || 'DKK',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        }).format(+value);
      }
      case 'HU': {
        return new Intl.NumberFormat('hu-HU', {
          style: 'currency',
          currency: countryCurrency || 'HUF',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        }).format(+value);
      }
      case 'PL': {
        return new Intl.NumberFormat('pl-PL', {
          style: 'currency',
          currency: countryCurrency || 'PLN',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        }).format(+value);
      }
      case 'RO': {
        return new Intl.NumberFormat('ro-RO', {
          style: 'currency',
          currency: countryCurrency || 'RON',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        }).format(+value);
      }
      case 'SE': {
        return new Intl.NumberFormat('sv-SE', {
          style: 'currency',
          currency: countryCurrency || 'SEK',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        }).format(+value);
      }
      default:
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: countryCurrency || 'EUR',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        }).format(+value);
    }
  }

  switch (countryCode) {
    case 'IE': {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: countryCurrency || 'EUR',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'GB': {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: countryCurrency || 'GBP',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'ID': {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: countryCurrency || 'IDR',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'PH': {
      return new Intl.NumberFormat('fil-PH', {
        style: 'currency',
        currency: countryCurrency || 'PHP',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'SG': {
      return new Intl.NumberFormat('en-SG', {
        style: 'currency',
        currency: countryCurrency || 'SGD',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'MY': {
      return new Intl.NumberFormat('ms-MY', {
        style: 'currency',
        currency: countryCurrency || 'MYR',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'CN': {
      return new Intl.NumberFormat('zh-CN', {
        style: 'currency',
        currency: countryCurrency || 'CNY',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'IN': {
      return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: countryCurrency || 'INR',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'TH': {
      return new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: countryCurrency || 'THB',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'VN': {
      return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: countryCurrency || 'VND',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    case 'AU': {
      return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: countryCurrency || 'AUD',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      }).format(+value);
    }
    default:
      return store?.getState()?.user?.adminCountryCode === 'eu'
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: countryCurrency || 'EUR',
            maximumFractionDigits: maxFraction,
            currencyDisplay: 'narrowSymbol',
          }).format(+value)
        : new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: countryCurrency || 'USD',
            maximumFractionDigits: maxFraction,
            currencyDisplay: 'narrowSymbol',
          }).format(+value);
  }
};
