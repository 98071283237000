import { combineReducers } from 'redux';

import { rootApi } from '../api';
import { adminConnectionsReducer } from './admin/connections/connections.reducer';
import { adminDiscountsReducer } from './admin/discounts/discounts.reducer';
import { adminEmailsReducer } from './admin/emails/emails.reducer';
import { adminErrorsReducer } from './admin/errors/errors.reducer';
import { adminOrdersReducer } from './admin/orders';
import { adminOverdueReducer } from './admin/overdue/overdue.reducer';
import { adminProductsReducer } from './admin/products/products.reducer';
import { restaurantsReducer } from './admin/restaurants';
import { suppliersReducer } from './admin/suppliers';
import { usersReducer } from './admin/users';
import { customersReducer } from './customers';
import { customersPerformanceReducer } from './customers-performance';
import { dashboardReducer } from './dashboard';
import { deliveriesReducer } from './deliveries';
import { driversReducer } from './drivers';
import { inventoryReducer } from './inventory';
import { manualProductsReducer } from './manual-products';
import { menuReducer } from './menu';
import { ordersReducer } from './orders';
import { pantryListReducer } from './pantry-lists';
import { priceExceptionReducer } from './price-exception';
import { productsReducer } from './products';
import { productsPurchasedReducer } from './products-purchased';
import { recipeProductsReducer } from './recipe-products/products.reducer';
import { recurringOrderReducer } from './recurring-order';
import { salesReducer } from './sales';
import { suppliersCategoriesReducer } from './suppliers-categories';
import { teammatesReducer } from './teammates';
import { userReducer } from './user';
import { wastageReducer } from './wastage';
import { adminThemesReducer } from './admin/themes/themes.reducer';
import { currentThemeApi } from '../api/admin-themes';

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  products: productsReducer,
  recipeProducts: recipeProductsReducer,
  customers: customersReducer,
  recurringOrders: recurringOrderReducer,
  pantryList: pantryListReducer,
  suppliersCategories: suppliersCategoriesReducer,
  user: userReducer,
  orders: ordersReducer,
  priceException: priceExceptionReducer,
  customersPerformance: customersPerformanceReducer,
  productsPurchased: productsPurchasedReducer,
  users: usersReducer,
  restaurants: restaurantsReducer,
  suppliers: suppliersReducer,
  deliveries: deliveriesReducer,
  drivers: driversReducer,
  menu: menuReducer,
  inventory: inventoryReducer,
  adminOrders: adminOrdersReducer,
  adminProducts: adminProductsReducer,
  adminConnections: adminConnectionsReducer,
  adminEmails: adminEmailsReducer,
  adminOverdueExports: adminOverdueReducer,
  adminErrors: adminErrorsReducer,
  adminDiscounts: adminDiscountsReducer,
  sales: salesReducer,
  wastage: wastageReducer,
  teammates: teammatesReducer,
  manualProducts: manualProductsReducer,
  themes: adminThemesReducer,
  [rootApi.reducerPath]: rootApi.reducer,
  [currentThemeApi.reducerPath]: currentThemeApi.reducer,
});

export default rootReducer;
