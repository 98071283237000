import React, { memo, SetStateAction, useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';
import uniq from 'lodash/uniq';
import LinesEllipsis from 'react-lines-ellipsis';
import { useDebouncedCallback } from 'use-debounce';

import {
  useArchiveManualProductMutation,
  useDeleteManualProductMutation,
  useRestoreManualProductMutation,
} from '../../../../../api/product';
import { Product, UpdateManualSupplierProduct } from '../../../../../api/product/types';
import SvgEditIcon from '../../../../../assets/icons/EditIcon';
import { BlurredImage } from '../../../../../shared/components/blurred-image';
import { CommonCheckbox } from '../../../../../shared/components/common-checkbox';
import { Dialog } from '../../../../../shared/components/dialog';
import { DropdownMenu } from '../../../../../shared/components/dropdown-menu';
import { MoreButton } from '../../../../../shared/components/more-button';
import { roundNumbers } from '../../../../../shared/helpers/roundNumbers';
import { ToastService } from '../../../../../shared/services/toastService';
import { useAppDispatch } from '../../../../../store';
import { setManualProductsUpdateData } from '../../../../../store/manual-products';
import { DeleteProductLogo } from '../../../../AppDrawer/SupplierDrawer/Product/common/delete-product-logo';
import { UploadProductLogo } from '../../../../AppDrawer/SupplierDrawer/Product/common/upload-product-logo';

import { useStyles } from './style';

interface Props {
  item: Product;
  editMode: boolean;
  onEditRow: () => void;
  rowEditFinished: boolean;
  lockedSupplier: boolean;
  setEditedRows: React.Dispatch<SetStateAction<number[]>>;
}

const Component: React.FC<Props> = ({ editMode, item, onEditRow, rowEditFinished, lockedSupplier, setEditedRows }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);
  const [isImageDeleteModalOpen, setIsImageDeleteModalOpen] = useState(false);
  const [isDeleteWarningShown, setIsDeleteWarningShown] = useState(false);
  const [isArchiveWarningShown, setIsArchiveWarningShown] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);

  const [rowData, setRowData] = useState<UpdateManualSupplierProduct | Omit<UpdateManualSupplierProduct, 'id'>>({
    unit: '',
    name: '',
  });

  const [deleteProduct] = useDeleteManualProductMutation();
  const [restoreProduct] = useRestoreManualProductMutation();
  const [archiveProduct] = useArchiveManualProductMutation();

  const openImageUpload = useCallback(() => {
    setIsImageUploadModalOpen(true);
  }, []);

  const onConfirmDelete = () => {
    deleteProduct({ productId: item.id, supplierId: item.supplier.id });
    setIsDeleteWarningShown(false);
  };

  const onImageDelete = () => {
    closeImageDelete();
    setRowData((prev) => ({
      ...prev,
      ...{
        id: item.id,
        picture: null,
      },
    }));
  };

  const closeImageDelete = useCallback(() => {
    setIsImageDeleteModalOpen(false);
  }, []);

  const onConfirmArchive = () => {
    archiveProduct({ productId: item.id, supplierId: item.supplier.id });
    setIsArchiveWarningShown(false);
  };

  const closeImageUpload = useCallback(() => {
    setIsImageUploadModalOpen(false);
  }, []);

  const onImageUploaded = (blob: Blob) => {
    setRowData((prev) => ({
      ...prev,
      ...{
        id: item.id,
        picture: new File([blob], item.name),
      },
    }));
    setEditedRows((prevState) => uniq([...prevState, item.id]));
  };

  const onChange = (newValue: string, field: 'name' | 'product_id' | 'brand' | 'product_price' | 'unit' | 'sub_category') => {
    setRowData((prev) => ({
      ...prev,
      ...{
        id: item.id,
        [field]: field === 'product_price' ? (newValue === '0' ? '0' : roundNumbers(newValue)) : newValue,
      },
    }));
  };

  const onUpdateGst = () => {
    setRowData((prev) => ({
      ...prev,
      ...{
        id: item.id,
        gst: !prev.gst,
      },
    }));
  };

  const showLockedWarning = () => {
    ToastService.warning('Action is not allowed');
  };

  const debouncedStoreUpdate = useDebouncedCallback(() => {
    if ('id' in rowData) {
      dispatch(setManualProductsUpdateData(rowData));
    }
  }, 1000);

  useEffect(() => {
    setIsEditMode(editMode);
  }, [editMode]);

  useEffect(() => {
    debouncedStoreUpdate();
  }, [rowData]);

  useEffect(() => {
    setRowData({
      name: item.name,
      brand: item.brand,
      unit: item.unit,
      product_id: item.product_id,
      gst: item.gst,
      picture: item.picture_url,
      product_price: item?.product_price || 0,
      sub_category: item?.sub_category,
    });
  }, [item]);

  useEffect(() => {
    // @ts-ignore
    if (!rowData.id) {
      return;
    }
    if (
      rowData.gst !== item.gst ||
      (rowData.name !== item.name && rowData.name) ||
      rowData.brand !== item.brand ||
      (rowData.unit !== item.unit && rowData.unit) ||
      rowData.product_id !== item.product_id ||
      rowData.sub_category !== item.sub_category ||
      rowData.product_price !== item.product_price
    ) {
      setEditedRows((prevState) => uniq([...prevState, item.id]));
    } else {
      setEditedRows((prevState) => prevState.filter((rowId) => rowId !== item.id));
    }
  }, [rowData]);

  useEffect(() => {
    rowEditFinished && setIsEditMode(false);
  }, [rowEditFinished]);

  return (
    <>
      <div className={clsx(classes.row, item.archived_at && classes.rowArchived)}>
        <div className={classes.name}>
          <div>
            <DropdownMenu
              items={[
                <div onClick={lockedSupplier ? showLockedWarning : openImageUpload} key={'Upload Image'}>
                  Upload Image
                </div>,
              ]}
              triggerBtn={
                <div
                  className={clsx([
                    classes.productImgBox,
                    item.picture_url && classes.productImage,
                    item.archived_at && classes.productImgBoxArchived,
                  ])}
                >
                  {rowData.picture ? (
                    <>
                      <BlurredImage
                        src={typeof rowData.picture === 'string' ? rowData.picture : URL.createObjectURL(rowData.picture)}
                        alt={``}
                      />
                      <div className={clsx(classes.editImage)}>{lockedSupplier ? null : <SvgEditIcon className={classes.editIcon} />}</div>
                    </>
                  ) : lockedSupplier ? null : (
                    <SvgEditIcon className={classes.editIcon} />
                  )}
                </div>
              }
            />
          </div>
          {isEditMode && !lockedSupplier ? (
            <textarea
              rows={2}
              className={classes.textarea}
              value={rowData?.name}
              onChange={(e) => {
                onChange(e.target.value, 'name');
              }}
            />
          ) : (
            <div>
              <LinesEllipsis text={item.name || ''} maxLine={2} />
            </div>
          )}
        </div>
        <div className={classes.brand}>
          {isEditMode && !lockedSupplier ? (
            <input
              className={classes.input}
              value={rowData?.brand || ''}
              onChange={(e) => {
                onChange(e.target.value, 'brand');
              }}
            />
          ) : (
            item.brand
          )}
        </div>
        <div className={classes.id}>
          {isEditMode && !lockedSupplier ? (
            <input
              className={classes.input}
              value={rowData?.product_id || ''}
              onChange={(e) => {
                onChange(e.target.value, 'product_id');
              }}
            />
          ) : (
            item.product_id
          )}
        </div>
        <div className={classes.unit}>
          {isEditMode && !lockedSupplier ? (
            <input
              className={classes.input}
              value={rowData?.unit || ''}
              onChange={(e) => {
                onChange(e.target.value, 'unit');
              }}
            />
          ) : (
            item.unit
          )}
        </div>
        <div className={classes.subCat}>
          {isEditMode && !lockedSupplier ? (
            <input
              className={classes.input}
              value={rowData?.sub_category || ''}
              onChange={(e) => {
                onChange(e.target.value, 'sub_category');
              }}
            />
          ) : (
            item.sub_category
          )}
        </div>
        <div className={classes.price}>
          {isEditMode ? (
            <input
              type={'number'}
              className={classes.input}
              value={rowData?.product_price || ''}
              onChange={(e) => {
                onChange(e.target.value, 'product_price');
              }}
            />
          ) : (
            item.product_price
          )}
        </div>
        <div className={classes.gst}>
          <CommonCheckbox checked={!!rowData.gst} name={''} onChange={onUpdateGst} disabled={!!lockedSupplier} />
        </div>
        <div className={classes.actions}>
          <DropdownMenu
            items={[
              <div
                onClick={() => {
                  setIsEditMode(true);
                  onEditRow();
                }}
                key={'Edit'}
              >
                Edit
              </div>,
              <div onClick={lockedSupplier ? showLockedWarning : setIsDeleteWarningShown.bind(null, true)} key='Delete'>
                Delete
              </div>,
              item.archived_at ? (
                <div
                  onClick={
                    lockedSupplier
                      ? showLockedWarning
                      : () => {
                          restoreProduct({ productId: item.id, supplierId: item.supplier.id });
                        }
                  }
                  key='restore'
                >
                  Restore Archived
                </div>
              ) : (
                <div onClick={lockedSupplier ? showLockedWarning : setIsArchiveWarningShown.bind(null, true)} key='Archive'>
                  Archive
                </div>
              ),
            ]}
            triggerBtn={
              <div>
                <MoreButton />
              </div>
            }
          />
        </div>
      </div>
      {isDeleteWarningShown && (
        <Dialog
          title='Are you sure you want to delete this product?'
          onCancel={setIsDeleteWarningShown.bind(null, false)}
          onConfirm={onConfirmDelete}
          customClass={classes.dialog}
        />
      )}
      {isArchiveWarningShown && (
        <Dialog
          title='Are you sure you want to archive this product?'
          onCancel={setIsArchiveWarningShown.bind(null, false)}
          onConfirm={onConfirmArchive}
          customClass={classes.dialog}
        />
      )}
      <UploadProductLogo isModalOpen={isImageUploadModalOpen} onClose={closeImageUpload} onSubmit={onImageUploaded} />
      <DeleteProductLogo isModalOpen={isImageDeleteModalOpen} onClose={closeImageDelete} onSubmit={onImageDelete} description={item.name} />
    </>
  );
};
export const ManualSupplierProductItem = memo(Component);
