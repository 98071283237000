import React, { MouseEvent } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { ArrowBack } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

import { useGetCustomerByIdQuery } from '../../../../api/customer';
import { useGeHiddenPages } from '../../../../api/teammates/hooks';
import { useMeQuery, usePrefetch as usePrefetchNotificationSetting } from '../../../../api/user';
import { SubscriptionPlanEnum } from '../../../../api/user/types';
import SvgLockIcon from '../../../../assets/icons/LockIcon';
import SvgMenuIconLarge from '../../../../assets/icons/MenuIconLarge';
import SvgProfileIcon from '../../../../assets/icons/ProfileIcon';
import LogoAcc from '../../../../assets/images/logo/Logo.svg';
import LogoCustomer from '../../../../assets/images/logo/Logo_Customer.svg';
import LogoWhite from '../../../../assets/images/logo/Logo_White.svg';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { CustomMenuButton } from '../../../../shared/components/menu-button';
import { UpgradeSubscriptionTooltip } from '../../../../shared/components/upgrade-subscription-tooltip';
import { setGtmDatalayer } from '../../../../shared/helpers/setGtmDatalayer';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { showPantryListExitWarning } from '../../../../store/pantry-lists';
import { showRecOrderExitWarning } from '../../../../store/recurring-order';
import {
  closeAccountMenu,
  closeMainMenu,
  getImpersonatedCustomer,
  getIsAccountMenuOpened,
  getIsImpersonated,
  getIsMainMenuOpened,
  getIsRestaurant,
  getMySubscriptionType,
  logoutThunk,
  openMainMenu,
} from '../../../../store/user';
import { backToAdminThunk, backToSupplierThunk } from '../../../../store/user/user.actions';
import { colorVariables } from '../../../../styles/colorVariables';
import { NavBarMenu } from '../navbar-menu';
import { customerLinks, supplierLinks } from '../nested-account-menu';
import { Cart } from './cart';

import { useRippleStyles } from '../../../../styles/customRipple';
import { useStyles } from './style';
import { useCurrentTheme } from '../../../../api/admin-themes/hooks';
import { ThemeColors } from '../../../../api/admin-themes/types';

interface Props {
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  bottomComponent?: React.ReactNode;
  isAccount?: boolean;
  withTopNavigation?: boolean;
}

export const TopNavBar: React.FC<Props> = ({ leftComponent, rightComponent, bottomComponent, isAccount, withTopNavigation = false }) => {
  const { logoUrl, colors } = useCurrentTheme();
  const classes = useStyles({ textColor: colors[ThemeColors.navBarText], bgColor: colors[ThemeColors.navBarBg] });
  const rippleClasses = useRippleStyles();
  const { isMobile, isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { state: navState }: { state: { recOrderId?: number; pantryListId?: number } | null } = useLocation();

  const prefetchNotification = usePrefetchNotificationSetting('getNotificationSetting');
  const { data: user } = useMeQuery();
  const isMainMenuOpened = useAppSelector(getIsMainMenuOpened);
  const isAccountMenuOpened = useAppSelector(getIsAccountMenuOpened);
  const isRestaurant = useAppSelector(getIsRestaurant);
  const subscriptionType = useAppSelector(getMySubscriptionType);
  const impersonated = useAppSelector(getIsImpersonated);
  const impersonatedCustomer = useAppSelector(getImpersonatedCustomer);
  const { customerName } = useGetCustomerByIdQuery(impersonatedCustomer?.connection_id as number, {
    skip: !impersonatedCustomer?.connection_id,
    selectFromResult: ({ data }) => ({
      customerName: data?.restaurant_company?.name,
    }),
  });
  const hiddenLinks = useGeHiddenPages();

  const logout = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(logoutThunk());
  };

  const hamburgerHandler = () => {
    if (navState?.recOrderId) {
      dispatch(
        showRecOrderExitWarning({
          handler: () => {
            dispatch(openMainMenu());
          },
        }),
      );
      return;
    }
    if (navState?.pantryListId) {
      dispatch(
        showPantryListExitWarning({
          handler: () => {
            dispatch(openMainMenu());
          },
        }),
      );
      return;
    }
    if (isAccountMenuOpened || isMainMenuOpened) {
      dispatch(closeAccountMenu());
      dispatch(closeMainMenu());
    } else {
      dispatch(openMainMenu());
    }
  };

  const closeMenus = () => {
    isAccountMenuOpened && dispatch(closeAccountMenu());
    dispatch(closeMainMenu());
  };

  const preventClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onBackToAdmin = () => {
    impersonatedCustomer ? dispatch(backToSupplierThunk()) : dispatch(backToAdminThunk());
  };

  const goHome = () => {
    if (navState?.recOrderId) {
      dispatch(
        showRecOrderExitWarning({
          handler: () => {
            push('/browse_by_supplier');
          },
        }),
      );
      return;
    }
    if (navState?.pantryListId) {
      dispatch(
        showPantryListExitWarning({
          handler: () => {
            push('/browse_by_supplier');
          },
        }),
      );
      return;
    }
    setGtmDatalayer({
      event: 'header',
      eventCategory: 'logo_click',
    });
    push('/browse_by_supplier');
  };

  const onLinkHover = (link: string) => {
    link === '/account/notifications' && prefetchNotification();
  };

  return (
    <div
      className={clsx({
        [classes.topBar]: true,
        [classes.accountStyle]: isAccount && isDesktop,
        [classes.topBarCustomer]: isRestaurant,
      })}
    >
      <div className={clsx(classes.topBox, isRestaurant && classes.topBoxCustomer)}>
        <div className={clsx(classes.hamburgerCustomer, !isRestaurant && classes.hidden)}>
          {!isMobile && (
            <div className={classes.hamburgerWrap}>
              <div
                onClick={impersonatedCustomer ? undefined : hamburgerHandler}
                className={clsx(
                  classes.iconWrap,
                  isMainMenuOpened && classes.iconWrapActive,
                  rippleClasses.ripple,
                  impersonatedCustomer && classes.hamburgerInactive,
                )}
              >
                <SvgMenuIconLarge
                  color={colors[ThemeColors.navBarText] || colorVariables.steelGrey}
                  width={24}
                  height={24}
                  className={classes.largeHamburgerIcon}
                />
              </div>
              <div onClick={goHome} className={classes.link}>
                <img src={logoUrl || LogoCustomer} alt='Logo' height={35} style={{ objectFit: 'contain', maxWidth: 200 }} />
              </div>
            </div>
          )}
        </div>
        <div className={classes.leftBox}>
          {isMobile ? (
            <div className={classes.hamburgerWrap}>
              <CustomMenuButton onClick={impersonatedCustomer ? undefined : hamburgerHandler} isActive={isMainMenuOpened} />
              {isRestaurant ? (
                <div className={classes.logo_link} onClick={goHome}>
                  <img src={logoUrl || LogoCustomer} alt='Logo' height={31} style={{ objectFit: 'contain', maxWidth: 200 }} />
                </div>
              ) : (
                <NavLink to='/dashboard' className={classes.logo_link}>
                  <img alt='Logo' src={logoUrl || LogoWhite} height={31} style={{ objectFit: 'contain', maxWidth: 200 }} />
                </NavLink>
              )}
              <ThemedButton
                onClick={onBackToAdmin}
                title={impersonatedCustomer ? 'Return home' : 'Return to admin'}
                buttonStyle='blueButton'
                isSmall={true}
                startIcon={<ArrowBack />}
                customClass={clsx(classes.backAdminBtn, !impersonated && !impersonatedCustomer && classes.backAdminBtnHidden)}
                width={140}
              />
            </div>
          ) : isDesktop && isAccount && !isRestaurant ? (
            <>
              <NavLink to={'/dashboard'} className={classes.logoBox}>
                <img src={logoUrl || LogoAcc} alt='logo' height={31} style={{ objectFit: 'contain', maxWidth: 200 }} />
              </NavLink>
              <ThemedButton
                onClick={onBackToAdmin}
                title='Return to admin'
                buttonStyle='blueButton'
                isSmall={true}
                startIcon={<ArrowBack />}
                customClass={clsx(classes.backAdminBtn, !impersonated && classes.backAdminBtnHidden)}
                width={180}
              />
            </>
          ) : isDesktop || isRestaurant ? (
            <ThemedButton
              onClick={onBackToAdmin}
              title={impersonatedCustomer ? 'Return home' : 'Return to admin'}
              buttonStyle='blueButton'
              isSmall={true}
              startIcon={<ArrowBack />}
              customClass={clsx(classes.backAdminBtn, !impersonated && !impersonatedCustomer && classes.backAdminBtnHidden)}
              width={180}
            />
          ) : (
            leftComponent
          )}
        </div>
        <div className={classes.rightBox} onClick={isRestaurant ? closeMenus : undefined}>
          {isRestaurant ? <Cart /> : rightComponent}
          {isDesktop && (
            <>
              <div className={classes.profileMenuWrap}>
                <NavBarMenu
                  disabled={!!impersonatedCustomer}
                  isSupplier={!isRestaurant}
                  triggerBtn={
                    !isRestaurant ? (
                      <>
                        <div className={classes.profileText}>{user?.company.name || ''}</div>
                        <div className={clsx([classes.profileLogoWrap, isAccount && classes.logoActive])}>
                          {user?.company?.picture_url && <img src={user?.company?.picture_url} alt='Logo' />}
                        </div>
                        <ArrowDropDownIcon className={classes.arrow} />
                      </>
                    ) : (
                      <div className={clsx(classes.customerMenuTrigger, impersonatedCustomer && classes.customerMenuTriggerDisabled)}>
                        <SvgProfileIcon color={colors[ThemeColors.navBarText] || colorVariables.steelGrey} width={18} height={18} />
                        <div className={classes.customerName}>
                          {impersonatedCustomer ? customerName : `${user?.first_name || ''} ${user?.last_name || ''}`}
                        </div>
                        <KeyboardArrowDownIcon className={classes.customerArrow} />
                      </div>
                    )
                  }
                  items={(isRestaurant ? customerLinks : supplierLinks)
                    .filter((link) => link.link !== '/login')
                    .map(({ link, icon, title, disabled, permissionKey }) =>
                      link === '/account/teammates' &&
                      (subscriptionType === SubscriptionPlanEnum.FREE || subscriptionType === SubscriptionPlanEnum.NORMAL) ? (
                        <NavLink
                          key={link}
                          to={link}
                          activeClassName={clsx(classes.activeLink)}
                          className={clsx(
                            classes.linkWrap,
                            !isRestaurant && subscriptionType === SubscriptionPlanEnum.FREE && classes.disabledSupplierSide,
                            isRestaurant && subscriptionType === SubscriptionPlanEnum.NORMAL && classes.disabledRestaurantSide,
                          )}
                          exact={true}
                          onClick={preventClick}
                          onMouseEnter={onLinkHover.bind(null, link)}
                        >
                          <div className={classes.imageWrap}>{icon}</div>
                          <div>{title}</div>
                          <UpgradeSubscriptionTooltip
                            text='Upgrade your subscription plan to unlock ‘Teammates’ options'
                            placement={'left'}
                          >
                            <div className={classes.lockWrap}>
                              <SvgLockIcon color={colorVariables.navy} />
                            </div>
                          </UpgradeSubscriptionTooltip>
                        </NavLink>
                      ) : (
                        <NavLink
                          key={link}
                          to={link}
                          activeClassName={clsx(classes.activeLink, isRestaurant && classes.activeLinkCustomer)}
                          className={clsx(
                            classes.linkWrap,
                            disabled && !isRestaurant && classes.disabledSupplierSide,
                            (disabled || (Array.isArray(hiddenLinks) && permissionKey && hiddenLinks.includes(permissionKey))) &&
                              isRestaurant &&
                              classes.disabledRestaurantSide,
                          )}
                          exact={
                            link !== '/account/help' &&
                            link !== '/account/billing' &&
                            link !== '/account/venue' &&
                            !link.includes('/suppliers')
                          }
                          onClick={disabled ? preventClick : title === 'Logout' ? logout : undefined}
                          onMouseEnter={onLinkHover.bind(null, link)}
                        >
                          <div className={classes.imageWrap}>{icon}</div>
                          <div>{title}</div>
                        </NavLink>
                      ),
                    )}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {bottomComponent && !isDesktop && !isMainMenuOpened && (
        <div
          className={clsx([classes.bottomBox, withTopNavigation && classes.withTopNavigation, isRestaurant && classes.customerBottomBox])}
        >
          {bottomComponent}
        </div>
      )}
    </div>
  );
};
