import { createEntityAdapter, createReducer, EntityState, isAnyOf } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';
import { inventoryApi } from '../../api/inventory';
import { Inventory, InventoryFilter, InventorySortBy } from '../../api/inventory/types';
import { logoutThunk } from '../user/user.actions';
import {
  resetInventoryList,
  setInventoryFilter,
  setInventoryKeyword,
  setInventoryListMode,
  setInventoryPage,
  setInventorySort,
  setInventorySortFilter,
  setInventoryStorageKeyword,
  setReplenishLoading,
  setSelectedInventoryStorageSupplier,
  setSelectedInventorySupplier,
  setSelectedStorage,
} from './inventory.actions';

export const inventoryAdapter = createEntityAdapter<Inventory>();

interface State {
  sortBy?: InventorySortBy;
  filterBy?: InventoryFilter;
  keyword?: string;
  selectedStorage: null | string;
  selectedSupplier: null | number;
  list: EntityState<Inventory>;
  currentPage: number;
  listMode: boolean;
  storageKeyword?: string;
  storageSelectedSupplier: null | number;
  replenishLoading?: boolean;
}

const initialState: State = {
  list: inventoryAdapter.getInitialState(),
  sortBy: undefined,
  keyword: undefined,
  selectedStorage: null,
  selectedSupplier: null,
  currentPage: 1,
  filterBy: undefined,
  listMode: true,
  replenishLoading: false,
  storageSelectedSupplier: null,
};

export const inventoryReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setInventoryPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(resetInventoryList, (state) => {
    state.list = inventoryAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addCase(setInventoryListMode, (state, { payload }) => {
    state.listMode = payload;
  });
  builder.addCase(setSelectedStorage, (state, { payload }) => {
    state.selectedStorage = payload;
  });
  builder.addCase(setInventorySort, (state, { payload }) => {
    state.sortBy = payload;
    state.currentPage = 1;
    state.list = inventoryAdapter.getInitialState();
  });
  builder.addCase(setInventorySortFilter, (state, { payload }) => {
    if (!isEqual(state.filterBy, payload.filter) || !isEqual(payload.sort, state.sortBy)) {
      state.filterBy = payload.filter;
      state.sortBy = payload.sort;
      state.currentPage = 1;
      state.list = inventoryAdapter.getInitialState();
    }
  });
  builder.addCase(setSelectedInventorySupplier, (state, { payload }) => {
    if (state.selectedSupplier === payload) {
      return;
    }
    state.selectedSupplier = payload;
    state.currentPage = 1;
    state.list = inventoryAdapter.getInitialState();
  });
  builder.addCase(setInventoryKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
    state.list = inventoryAdapter.getInitialState();
  });
  builder.addCase(setInventoryFilter, (state, { payload }) => {
    if (!isEqual(state.filterBy, payload)) {
      state.filterBy = payload;
      state.currentPage = 1;
      state.list = inventoryAdapter.getInitialState();
    }
  });
  builder.addCase(setSelectedInventoryStorageSupplier, (state, { payload }) => {
    state.storageSelectedSupplier = payload;
  });
  builder.addCase(setInventoryStorageKeyword, (state, { payload }) => {
    state.storageKeyword = payload;
  });
  builder.addCase(setReplenishLoading, (state, { payload }) => {
    state.replenishLoading = payload;
  });
  builder.addMatcher(
    isAnyOf(inventoryApi.endpoints.editInventory.matchFulfilled, inventoryApi.endpoints.setUpIncompletedInventory.matchFulfilled),
    (state, { payload }) => {
      state.list = inventoryAdapter.upsertOne(state.list, payload.inventory_product);
    },
  );
  builder.addMatcher(inventoryApi.endpoints.getInventories.matchFulfilled, (state, action) => {
    if (
      state.selectedSupplier === action.meta.arg.originalArgs.supplierId &&
      !!state.filterBy?.favourite === !!action.meta.arg.originalArgs.favourite &&
      state.filterBy?.status === action.meta.arg.originalArgs.status
    ) {
      state.list = inventoryAdapter.setMany(state.list, action.payload.inventory_products);
    }
  });
  builder.addMatcher(
    isAnyOf(inventoryApi.endpoints.favoriteInventory.matchFulfilled, inventoryApi.endpoints.unFavoriteInventory.matchFulfilled),
    (state, { payload }) => {
      state.list = state.filterBy?.favourite
        ? inventoryAdapter.removeOne(state.list, payload.product.id)
        : inventoryAdapter.upsertOne(state.list, payload.product);
    },
  );
  builder.addMatcher(inventoryApi.endpoints.deleteInventory.matchFulfilled, (state, action) => {
    state.list = inventoryAdapter.removeOne(state.list, action.meta.arg.originalArgs);
  });
  builder.addMatcher(inventoryApi.endpoints.saveInventoryProducts.matchFulfilled, (state) => {
    state.list = inventoryAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addMatcher(inventoryApi.endpoints.skipForeverInventory.matchFulfilled, (state, action) => {
    state.list = inventoryAdapter.removeOne(state.list, action.meta.arg.originalArgs);
  });
});
