import { useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';

import { useAppSelector } from '../../store';
import {
  getRecipeRecipeBatchSize,
  getSelectedArchivedRecipeTagsIds,
  getSelectedRecipeTagsIds,
  getSelectedSubRecipeTagsIds,
} from '../../store/menu';
import { getMySubscriptionType } from '../../store/user';
import { SubscriptionPlanEnum } from '../user/types';
import { useGetMenusQuery, useGetRecipeByIdQuery, useGetRecipesQuery } from './index';
import { Menu, Recipe, RecipeIngredient, RecipeTag, SubRecProduct } from './types';

export const useGetActiveMenuList = (sub?: boolean) => {
  const selectedTags = useAppSelector(getSelectedRecipeTagsIds);
  const selectedSubTags = useAppSelector(getSelectedSubRecipeTagsIds);
  const recipeIds = useGetActiveRecipeIdsByTags(sub ? selectedSubTags : selectedTags);
  const { list, loading } = useGetMenusQuery(sub, {
    selectFromResult: ({ data, isLoading }) => ({
      list: data?.menus,
      loading: isLoading,
    }),
  });
  const menus = useMemo(() => {
    return list?.reduce((acc: Menu[], el: Menu) => {
      if (!el.deleted_at && !el.archived_at && ((el.sub_recipe_category && sub) || (!el.sub_recipe_category && !sub))) {
        acc.push({
          ...el,
          recipes: el.recipes.filter(
            (rec) =>
              ((sub && rec.is_sub_recipe) || (!sub && !rec.is_sub_recipe)) &&
              !rec.archived_at &&
              (!(sub ? selectedSubTags : selectedTags).length ||
                ((sub ? selectedSubTags : selectedTags).length && recipeIds?.includes(+rec.id))),
          ),
        });
      }
      return acc;
    }, []);
  }, [list, recipeIds, selectedTags, selectedSubTags, sub]);
  return { list: menus, loading };
};

export const useGetMenuList = () => {
  const { list } = useGetMenusQuery(undefined, {
    selectFromResult: ({ data }) => ({
      list: data?.menus,
    }),
  });
  const menus = useMemo(() => {
    return list?.reduce((acc: Menu[], el: Menu) => {
      if (!el.deleted_at && !el.archived_at && !el.sub_recipe_category) {
        acc.push(el);
      }
      return acc;
    }, []);
  }, [list]);
  return { menus };
};

export const useGetArchivedRecipesList = (filterByTag?: boolean) => {
  const { tags } = useGetArchivedRecipesTags();
  const selectedTags = useAppSelector(getSelectedArchivedRecipeTagsIds);
  const { data, isLoading } = useGetRecipesQuery({});
  const { data: subRecData, isLoading: subRecLoading } = useGetRecipesQuery({ sub_recipe: true });
  const archivedList = useMemo(() => {
    return [...(data?.recipies || []), ...(subRecData?.recipies || [])].filter(
      (el) =>
        !!el.archived_at &&
        (!filterByTag ||
          !selectedTags.length ||
          (filterByTag &&
            el.recipe_tags.some((tag) => selectedTags.map((tagId) => (tags || []).find((t) => t.id === tagId)?.name).includes(tag.name)))),
    );
  }, [data, selectedTags, subRecData]);
  return { archivedList, loading: isLoading || subRecLoading };
};

export const useGetActiveRecipesTags = (sub?: boolean) => {
  const { list } = useGetRecipesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        list: data?.recipies.reduce((acc: RecipeTag[], el: Recipe) => {
          if (!el.archived_at && ((!el.is_sub_recipe && !sub) || (el.is_sub_recipe && sub))) {
            acc.push(...el.recipe_tags);
          }
          return acc;
        }, []),
      }),
    },
  );
  return { tags: uniqBy(list, 'name') };
};

export const useGetActiveRecipes = (mostSales?: boolean) => {
  const plan = useAppSelector(getMySubscriptionType);
  const { list, loading } = useGetRecipesQuery(
    { mostSales },
    {
      selectFromResult: ({ data, isFetching }) => ({
        loading: isFetching,
        list: data?.recipies.reduce((acc: Recipe[], el: Recipe) => {
          if (!el.archived_at && !el.is_sub_recipe && !el.menu.deleted_at && !el.menu.archived_at) {
            acc.push(el);
          }
          return acc;
        }, []),
      }),
      skip: plan === SubscriptionPlanEnum.NORMAL,
    },
  );
  return { list, loading };
};

export const useGetArchivedRecipesTags = () => {
  const { list } = useGetRecipesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        list: data?.recipies.reduce((acc: RecipeTag[], el: Recipe) => {
          if (!!el.archived_at) {
            acc.push(...el.recipe_tags);
          }
          return acc;
        }, []),
      }),
    },
  );
  return { tags: uniqBy(list, 'name') };
};

export const useGetActiveRecipeIdsByTags = (tagsIds: number[]) => {
  const { tags } = useGetActiveRecipesTags();
  const { data } = useGetRecipesQuery({});
  return useMemo(() => {
    return data?.recipies
      .filter((recipe) =>
        recipe.recipe_tags.some((tag) => tagsIds.map((el) => (tags || []).find((t) => t.id === el)?.name).includes(tag.name)),
      )
      .map((rec) => rec.id);
  }, [data, tagsIds]);
};

export const useSelectSubRecipeIngredients = (id: number, skip?: boolean, parentQty?: number) => {
  const { data: subRecipes } = useGetRecipesQuery({ sub_recipe: true }, { skip });
  const batchSize = useAppSelector(getRecipeRecipeBatchSize);

  return useMemo(() => {
    return (
      subRecipes?.recipies?.reduce((acc: RecipeIngredient[], el) => {
        if (el.id === id) {
          acc = [
            ...acc,
            ...el.ingredients.map((ingr) => ({
              ...ingr,
              batchedQuantity: (ingr.quantity || 0) * (parentQty || 1),
              cartQuantity: ingr.cartQuantity || (ingr.quantity || 0) * (parentQty || 1),
            })),
          ];
        }
        return acc;
      }, []) || []
    );
  }, [subRecipes, parentQty, batchSize]);
};

export const useRecipeCartProducts = (recipeId?: number) => {
  const { data: subRecipes } = useGetRecipesQuery({ sub_recipe: true });
  const { data: recipe } = useGetRecipeByIdQuery(recipeId as number, {
    skip: !recipeId,
  });
  const batchSize = useAppSelector(getRecipeRecipeBatchSize);

  const extractNestedRecipes = (recipeItem?: Recipe, srQty?: number): any[] => {
    const prodIngrs =
      recipeItem?.ingredients?.reduce((acc: SubRecProduct[], el) => {
        if (!el.sub_recipe_id) {
          acc.push({ ...el, subRecQty: srQty });
        }
        return acc;
      }, []) || [];

    const recIngrs = recipeItem?.ingredients?.filter((el) => !!el.sub_recipe_id) || [];
    const subRecEntry = subRecipes?.recipies.filter((el) => recIngrs.some((item) => item.sub_recipe_id === el.id)) || [];

    const nestedIngrs = subRecEntry.flatMap((el) => {
      const tempEntry = recIngrs?.find((item) => item.sub_recipe_id === el.id);
      const tempEntryQty = tempEntry?.batchedQuantity || tempEntry?.quantity || 0;
      return extractNestedRecipes(el, tempEntryQty * (srQty || 1));
    });

    return [...prodIngrs, ...nestedIngrs];
  };
  return useMemo(() => extractNestedRecipes(recipe as Recipe, undefined), [recipe, subRecipes, batchSize]);
};

export const useSelectSubRecipeById = (id?: number) => {
  const { data: subRecipes } = useGetRecipesQuery({ sub_recipe: true });

  return useMemo(() => {
    return subRecipes?.recipies?.find((el) => el.id === id);
  }, [subRecipes, id]);
};
