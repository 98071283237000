import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';

export const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '15px 10px',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      fontSize: 12,
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'rgba(228, 252, 245, 0.5)',
      },
    },
    rowArchived: {
      opacity: 0.7,
    },
    name: {
      width: '25%',
      display: 'flex',
      alignItems: 'center',
    },
    brand: {
      width: '11%',
      paddingLeft: 5,
    },
    id: {
      width: '10%',
      paddingLeft: 5,
    },
    unit: {
      width: '13%',
      paddingLeft: 5,
    },
    subCat: {
      width: '13%',
      paddingLeft: 5,
    },
    price: {
      width: '10%',
      paddingLeft: 5,
    },
    gst: {
      width: '5%',
    },
    actions: {
      width: '3%',
    },
    productImgBox: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 38,
      height: 38,
      borderRadius: '50%',
      marginRight: 10,
      backgroundColor: 'rgba(191, 201, 213, 0.6)',
      flexShrink: 0,
      cursor: 'pointer',
      overflow: 'hidden',
      '&:hover div': {
        visibility: 'visible',
      },
    },
    productImgBoxArchived: {
      cursor: 'default',
      opacity: 0.3,
      '&:hover div': {
        visibility: 'hidden',
      },
    },
    productImage: {
      backgroundColor: colorVariables.white,
    },
    editImage: {
      visibility: 'hidden',
      position: 'absolute',
      width: 38,
      height: 38,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(191, 201, 213, 0.6)',
      zIndex: 1,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    editIcon: {
      color: colorVariables.white,
      fontSize: 14,
    },
    textarea: {
      resize: 'none',
      width: '100%',
      border: `1px solid ${colorVariables.steelGrey}`,
      padding: 5,
      '&:hover, &:focus': {
        border: `1px solid ${colorVariables.blue}`,
      },
    },
    input: {
      width: '100%',
      border: `1px solid ${colorVariables.steelGrey}`,
      padding: 5,
      '&:hover, &:focus': {
        border: `1px solid ${colorVariables.blue}`,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    dialog: {
      position: 'absolute',
      top: 40,
      right: 20,
    },
  }),
);
